/* eslint-disable no-await-in-loop */
/* eslint-disable new-cap */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-loop-func */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from '@unform/web';
import { HiCheck, HiOutlineBan } from 'react-icons/hi';
import { useHistory, useParams } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { Container, Modal } from './styles';
import Input from '~/components/Input';
import {
  KrClose,
  KrDoubt,
  KrShoppingCart,
  KrUsers,
} from '~/components/KoroIcons';
import checklist from '~/assets/icons/checklist.svg';
import ButtonBack from '~/components/ButtonBack';
import InputCheckbox from '~/components/InputCheckbox';
import PaymentMethod from '~/components/PaymentMethod';
import { useCart } from '~/hooks/Cart';
import { formatPrice } from '~/utils/format';
import api from '~/services/api';
import logoQrcode from '~/assets/logos/logo-qrcode.svg';
import Toast from '~/utils/toast';
import { useAuth } from '~/hooks/Auth';
import ModalTerms from './ModalTerms';
import ModalRegulations from './ModalRegulations';
import Ticket from '~/components/Ticket';
import { useResize } from '~/hooks/Resize';

interface IPeople {
  name?: string;
  document?: string;
}

interface ISummary {
  modality: string;
  category: string;
  peoples: IPeople[];
  subtotal: number;
  quantity: number;
}

interface IParams {
  slug: string;
}

interface IValue {
  id: number;
  type: string;
  installment: number;
  tax_percent: string;
  tax_price: string;
  koro_deadline: number;
  gateway_deadline: number;
}

interface IGatewayResponse {
  id: number;
  account_id: string;
  anticipation: boolean;
  company: {
    document: string;
    name: string;
    fantasy_name: string;
    support_email: string;
    finance_email: string;
    address: {
      street: string;
      number: string;
      neighborhood: string;
      city: string;
      state: string;
      country: string;
      complement: string;
      zip_code: string;
    };
  };
  values: IValue[];
}

interface ITicketResponse {
  id: number;
  document: string;
  name: string;
  email: string;
  phone: string;
  code: string;
  orderItem: {
    event: {
      id: number;
      name: string;
      date: string;
      address: {
        street: string;
        number: string;
        neighborhood: string;
        city: string;
        state: string;
        country: string;
        complement: string;
        zip_code: string;
      };
    };
  };
  modality: {
    name: string;
  };
  batchCategory: {
    category: {
      name: string;
    };
  };
  kitUser?: {
    color?: {
      name: string;
    };
    size?: {
      size: string;
    };
  };
}

interface ITicket {
  id: number;
  code: string;
  event: string;
  event_date: string;
  name: string;
  document: string;
  modality: string;
  category: string;
  address: string;
  email: string;
  phone: string;
  event_id: string;
  kit?: {
    color?: string;
    size?: string;
  };
}

const CheckoutPayment: React.FC = () => {
  const params = useParams<IParams>();
  const { width } = useResize();
  const { user } = useAuth();
  const {
    cart,
    handleChangeItems,
    setFillBefore: setFillBeforeCard,
  } = useCart();
  const history = useHistory();
  const [terms, setTerms] = useState(false);
  const [payment, setPayment] = useState(false);
  const [validVoucher, setValidVoucher] = useState(false);
  const [invalidVoucher, setInvalidVoucher] = useState(false);
  const [serviceFee, setServiceFee] = useState(0);
  const [processingFee, setProcessingFee] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [success, setSuccess] = useState(false);
  const [regulations, setRegulations] = useState(false);
  const [term, setTerm] = useState(false);
  const [voucherId, setVoucherId] = useState<number | undefined>(undefined);
  const [qrCode, setQrCode] = useState('');
  const [show, setShow] = useState(false);
  const [eventId, setEventId] = useState<number | undefined>(undefined);
  const [fillBefore, setFillBefore] = useState(false);
  const [organizerId, setOrganizerId] = useState<number | undefined>(undefined);
  const [serviceTaxType, setServiceTaxType] = useState('Porcentagem');
  const [serviceTax, setServiceTax] = useState(0);
  const [processingTaxType, setProcessingTaxType] = useState('Porcentagem');
  const [processingTax, setProcessingTax] = useState(0);
  const [orderId, setOrderId] = useState<number | undefined>(undefined);
  const [generating, setGenerating] = useState(false);
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [hasPix, setHasPix] = useState(false);
  const [hasCredit, setHasCredit] = useState(false);

  useEffect(() => {
    if (orderId && fillBefore) {
      api
        .get<ITicketResponse[]>(`tickets/events/users/${eventId}/${orderId}`)
        .then((response) => {
          const data = response.data.map<ITicket>((ticket) => {
            return {
              id: ticket.id,
              code: ticket.code,
              event_id: ticket.orderItem.event.id.toString(),
              event: ticket.orderItem.event.name,
              event_date: ticket.orderItem.event.date,
              name: ticket.name,
              document: ticket.document,
              email: ticket.email,
              phone: ticket.phone,
              modality: ticket.modality.name,
              category: ticket.batchCategory.category.name,
              address: `${ticket.orderItem.event.address.street}, Nº ${ticket.orderItem.event.address.number}, ${ticket.orderItem.event.address.neighborhood}, ${ticket.orderItem.event.address.city}/${ticket.orderItem.event.address.state}, CEP: ${ticket.orderItem.event.address.zip_code}`,
              kit: ticket.kitUser
                ? {
                    color: ticket.kitUser.color?.name,
                    size: ticket.kitUser.size?.size,
                  }
                : undefined,
            };
          });

          setTickets(data);
        });
    }
  }, [eventId, fillBefore, orderId]);

  useEffect(() => {
    if (generating && tickets.length > 0) {
      let index = 0;
      for (const ticket of tickets) {
        if (ticket.document !== user.additionalInformation.document) {
          const divElement = document.getElementById(`ticket-${ticket.code}`);
          if (divElement) {
            const pdfWidth = divElement.offsetWidth;
            const pdfHeight = divElement.offsetHeight;
            html2canvas(divElement, {
              width: width > 550 ? pdfWidth + 85 : pdfWidth + 45,
              height: pdfHeight + 30,
            }).then((canvas) => {
              const imgData = canvas.toDataURL('image/png');
              const pdf = new jsPDF({
                unit: 'px',
                format: [pdfWidth, pdfHeight],
              });
              pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth, pdfHeight);
              const ticketFile = pdf.output('blob');
              const ticketFormData = new FormData();
              ticketFormData.append('ticket', ticketFile);
              ticketFormData.append('ticket_id', ticket.id.toString());
              api.post('tickets/emails', ticketFormData);
            });
          }
        }

        if (index === tickets.length - 1) {
          setSuccess(true);
          setGenerating(false);
        }
        index += 1;
      }
    }
  }, [
    generating,
    tickets,
    tickets.length,
    user.additionalInformation.document,
    width,
  ]);

  useEffect(() => {
    api
      .get(`events/${params.slug}`, {
        params: {
          withoutRelations: true,
        },
      })
      .then((response) => {
        setEventId(response.data.id);
        setFillBefore(response.data.fill_before);
        setFillBeforeCard(response.data.fill_before);
        setOrganizerId(response.data.organizer_id);
        setServiceTaxType(response.data.contract.service_type);
        setServiceTax(parseFloat(response.data.contract.service));
        setProcessingTaxType(response.data.contract.tax_type);
        setProcessingTax(parseFloat(response.data.contract.tax));
        setHasPix(
          response.data.paymentsMethods.some(
            (eventPaymentMethod: any) =>
              eventPaymentMethod.paymentMethod.name === 'Pix'
          )
        );
        setHasCredit(
          response.data.paymentsMethods.some(
            (eventPaymentMethod: any) =>
              eventPaymentMethod.paymentMethod.name === 'Crédito'
          )
        );
      });
  }, [params.slug, setFillBeforeCard]);

  const summary = useMemo(
    () =>
      cart.items.reduce((previous, current) => {
        const prev = previous;

        const itemIndex = prev.findIndex(
          (item) =>
            `${item.modality}-${item.category}` ===
            `${current.modality}-${current.category}`
        );

        if (itemIndex >= 0) {
          if (cart.isFillBeforeTicket) {
            prev[itemIndex].peoples.push({
              name: current.ticket?.name || '',
              document: current.ticket?.document || '',
            });
          }

          prev[itemIndex].quantity += 1;
          prev[itemIndex].subtotal = current.value * prev[itemIndex].quantity;
        } else {
          prev.push({
            modality: current.modality,
            category: current.category,
            peoples: cart.isFillBeforeTicket
              ? [
                  {
                    name: current.ticket?.name || '',
                    document: current.ticket?.document || '',
                  },
                ]
              : [],
            quantity: 1,
            subtotal: current.value * 1,
          });
        }
        return prev;
      }, [] as ISummary[]),
    [cart.isFillBeforeTicket, cart.items]
  );

  useEffect(() => {
    const data = summary.reduce((previous, current) => {
      return previous + current.subtotal;
    }, 0);

    setSubtotal(data);
  }, [eventId, processingFee, serviceFee, summary]);

  useEffect(() => {
    if (subtotal) {
      if (serviceTaxType === 'Porcentagem') {
        setServiceFee(subtotal * (serviceTax / 100));
      } else {
        setServiceFee(serviceTax);
      }
      if (processingTaxType === 'Porcentagem') {
        setProcessingFee(subtotal * (processingTax / 100));
      } else {
        setProcessingFee(processingTax);
      }
    }
  }, [processingTax, processingTaxType, serviceTax, serviceTaxType, subtotal]);

  useEffect(() => {
    setTotal(subtotal + serviceFee + processingFee - discount);
  }, [discount, processingFee, serviceFee, subtotal]);

  const handleChangeVoucher = useCallback(
    async (e) => {
      const { value } = e.target;
      if (value.length > 0) {
        const response = await api.get(`vouchers/${value}/${eventId}`);
        if (response.data) {
          let data = 0;
          if (response.data.percent) {
            const discountData =
              (subtotal / 100) * parseFloat(response.data.percent);
            data = subtotal - discountData;
            setDiscount(discountData);
          } else if (response.data.price) {
            data = subtotal - parseFloat(response.data.price);
            setDiscount(parseFloat(response.data.price));
          }
          setTotal(data);
          setInvalidVoucher(false);
          setValidVoucher(true);
          setVoucherId(response.data.id);
        } else {
          setVoucherId(undefined);
          setDiscount(0);
          setTotal(subtotal);
          setValidVoucher(false);
          setInvalidVoucher(true);
        }
      } else {
        setVoucherId(undefined);
        setDiscount(0);
        setTotal(subtotal);
        setValidVoucher(false);
        setInvalidVoucher(false);
      }
    },
    [eventId, subtotal]
  );

  const handleSubmit = useCallback(async () => {
    setPayment(true);
  }, []);

  const handlePaymentMethodSelected = useCallback(
    async (paymentMethod) => {
      const ticketsIds: number[] = [];
      try {
        let discountAwaitTime = 0;
        const formData = {
          event_id: eventId,
          organizer_id: organizerId,
          voucher_id: voucherId,
          name: user.name,
          email: user.email,
          service_fee: serviceFee,
          processing_fee: processingFee,
          discount,
          total,
          installments: paymentMethod.installments,
        };
        const response = await api.post('orders', formData);
        discountAwaitTime += response.duration || 0;

        await new Promise<void>((resolve) => {
          cart.items.forEach(async (item, index) => {
            const formDataTicket = {
              batch_category_id: item.batch_category_id,
              modality_id: item.modality_id,
              user_id: user.id,
              event_id: eventId,
              organizer_id: organizerId,
              document: item.ticket?.document,
              name: item.ticket?.name,
              email: item.ticket?.email,
              phone: item.ticket?.phone,
            };

            const responseTicket = await api.post('tickets', formDataTicket);

            if (
              item.ticket &&
              item.ticket.answers &&
              item.ticket.answers.length > 0
            ) {
              for (const answer of item.ticket.answers) {
                const formDataAnswer = {
                  ticket_id: responseTicket.data.id,
                  event_id: eventId,
                  question_type_id: answer.question_type_id,
                  question_id: answer.question_id,
                  alternative_id: answer.alternative_id,
                  text: answer.text,
                };

                await api.post('answers', formDataAnswer);
              }
            }

            if (item.ticket?.kit) {
              const kitUserFormData = {
                event_id: item.ticket.kit.event_id,
                kit_id: item.ticket.kit.kit_id,
                user_id: item.ticket.kit.user_id,
                ticket_id: responseTicket.data.id,
                color_id: item.ticket.kit.color_id,
                size_id: item.ticket.kit.size_id,
              };

              await api.post('kits-users', kitUserFormData);
            }

            ticketsIds.push(responseTicket.data.id);

            const formDataOrderItem = {
              order_id: response.data.id,
              batch_category_id: item.batch_category_id,
              ticket_id: responseTicket.data.id,
              event_id: eventId,
              price: item.value,
              quantity: item.quantity,
            };

            const responseOrderItem = await api.post(
              'orders-items',
              formDataOrderItem
            );
            discountAwaitTime += responseOrderItem.duration || 0;

            if (cart.items.length === index + 1) {
              resolve();
            }
          });
        });

        const totalwithOutDiscount = subtotal + serviceFee + processingFee;

        const gatewayResponse = await api.get<IGatewayResponse>('gateways/1');

        const gatewayValue = gatewayResponse.data.values.find(
          (value) =>
            value.type === paymentMethod.type &&
            value.installment === paymentMethod.installments
        );

        let gateway = total;
        let organizer_deadline = 0;
        let koro_deadline = 0;

        if (gatewayValue) {
          gateway =
            total * parseFloat(gatewayValue.tax_percent) +
            parseFloat(gatewayValue.tax_price);
          organizer_deadline = gatewayValue.gateway_deadline;
          koro_deadline = gatewayValue.koro_deadline;
        }

        const formDataPayment = {
          order_id: response.data.id,
          event_id: eventId,
          organizer_id: organizerId,
          card_id: paymentMethod.card?.id,
          name: paymentMethod.card?.name,
          number: paymentMethod.card?.fullNumber,
          due_date: paymentMethod.card?.due_date,
          flag: paymentMethod.card?.flag,
          cvc: paymentMethod.card?.cvc,
          total: parseFloat(totalwithOutDiscount.toFixed(2)),
          discount: parseFloat(discount.toFixed(2)),
          price: parseFloat(total.toFixed(2)),
          gateway: parseFloat(gateway.toFixed(2)),
          organizer: parseFloat((subtotal - discount).toFixed(2)),
          organizer_deadline,
          koro: parseFloat((serviceFee + processingFee - gateway).toFixed(2)),
          koro_deadline,
          payment_type: paymentMethod.type,
          document: paymentMethod.document,
          phone: paymentMethod.phone,
        };

        const responsePayments = await api.post('payments', formDataPayment);

        discountAwaitTime += responsePayments.duration || 0;

        if (responsePayments.data.qrCode) {
          setQrCode(responsePayments.data.qrCode);
          setShow(true);
          setSuccess(true);
        } else {
          handleChangeItems([]);

          const awaitTime = 6000 - discountAwaitTime;

          setTimeout(
            () => {
              if (!fillBefore) {
                setSuccess(true);
              } else {
                setGenerating(true);
                setOrderId(response.data.id);
              }
            },
            awaitTime > 0 ? awaitTime : 0
          );
        }
      } catch (error) {
        if (ticketsIds.length > 0) {
          for (const ticketId of ticketsIds) {
            await api.delete(`tickets/${ticketId}`);
          }
        }
        Swal.fire(
          'Oops...',
          'Ocorreu um erro com pagamento verifique seus dados e tente novamente, por favor',
          'error'
        ).then(() => {
          setPayment(false);
          setQrCode('');
          setShow(false);
        });
      }
    },
    [
      cart.items,
      discount,
      eventId,
      fillBefore,
      handleChangeItems,
      organizerId,
      processingFee,
      serviceFee,
      subtotal,
      total,
      user.email,
      user.id,
      user.name,
      voucherId,
    ]
  );

  const handleClickConfirm = useCallback(() => {
    sessionStorage.removeItem(`@Koro:qtyTickets_${eventId}`);
    history.push(`${process.env.PUBLIC_URL}/meus-ingressos/proximos`);
  }, [eventId, history]);

  const handleClickCopyPix = useCallback(async () => {
    await navigator.clipboard.writeText(qrCode);
    Toast.fire({
      icon: 'success',
      iconColor: '#ff6900',
      title: 'Código copiado!',
    });
  }, [qrCode]);

  return (
    <>
      {payment ? (
        <PaymentMethod
          payment
          onClickBack={() => setPayment(false)}
          onPaymentMethodSelected={handlePaymentMethodSelected}
          onClickConfirm={handleClickConfirm}
          success={success}
          generating={generating}
          hasPix={hasPix}
          hasCredit={hasCredit}
        />
      ) : (
        <Container>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-11 text-center pt-3">
                <ButtonBack className="btn-back" />

                <div className="d-flex align-items-center justify-content-between my-3">
                  <h2 className="h4 mb-0 d-flex align-items-center">
                    <img src={checklist} alt="Check" className="me-2" />
                    Resumo
                  </h2>
                </div>
                <h4 className="text-start mb-3">
                  Este é o resumo do seu pedido, verifique as informações para
                  prosseguir com o pagamento.
                </h4>
              </div>

              <div className="col-11">
                <Form
                  onSubmit={handleSubmit}
                  className="d-flex flex-column pb-3"
                >
                  <div className="border-ticket p-3 mb-3">
                    <h3 className="d-flex align-items-center h5 text-black">
                      <KrUsers size={20} color="#292623" className="me-2" />
                      Pessoas e Ingressos
                    </h3>
                    {summary.map((item, index) => (
                      <div
                        className={`${
                          summary.length - 1 !== index && 'border-bottom'
                        } mt-3`}
                      >
                        <p className="d-flex justify-content-between mb-0">
                          <span className="h6 fw-semibold text-black mb-1">
                            {item.quantity}x {item.modality}
                          </span>{' '}
                          <span className="h6 fw-semibold text-black mb-1">
                            {formatPrice(item.subtotal)}
                          </span>
                        </p>

                        <p className="">Categoria: {item.category || '-'}</p>
                        <ul>
                          {item.peoples.map((people) => (
                            <li className="pb-3">
                              <span className="d-block">
                                {people.name || 'Nome: Não preenchido'}
                              </span>
                              <span className="d-block">
                                Documento: {people.document || 'Não preenchido'}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                  <div className="discount mb-3">
                    <div className="title p-2">Inserir cupom de desconto</div>
                    <div className="bd-discount d-flex align-items-center px-2">
                      <Input
                        name="voucher"
                        className="ps-0"
                        onChange={handleChangeVoucher}
                        placeholder="Digite aqui"
                      />
                      {validVoucher && (
                        <HiCheck
                          size={16}
                          color="#00A3E0"
                          className="check-icon"
                        />
                      )}
                      {invalidVoucher && (
                        <HiOutlineBan
                          size={16}
                          color="#FF003D"
                          className="check-icon"
                        />
                      )}
                    </div>
                  </div>
                  <div className="border-ticket values p-3 mb-3">
                    <h3 className="d-flex align-items-center h5 text-black">
                      <KrShoppingCart
                        size={20}
                        color="#292623"
                        className="me-2"
                      />
                      Resumo de valores
                    </h3>
                    {summary.map((item) => (
                      <p className="d-flex mb-0 mt-3">
                        <span>
                          {item.quantity}x {item.modality}
                        </span>
                        <span className="dot" />
                        <span>{formatPrice(item.subtotal)}</span>
                      </p>
                    ))}

                    <p className="d-flex mb-0 mt-3">
                      <span>Taxa de serviço</span>
                      <span className="dot" />
                      <span>{formatPrice(serviceFee)}</span>
                    </p>
                    <p className="d-flex mb-0 mt-3 position-relative">
                      <span>
                        Taxa de processamento
                        <button
                          type="button"
                          className="tooltip-tax border-0 bg-transparent ms-1"
                          data-tooltip="A taxa de processamento é o custo que será repassado ao serviço de transação online para operacionalizar o pagamento"
                        >
                          <KrDoubt size={16} color="#8C8C8C" />
                        </button>
                      </span>
                      <span className="dot" />
                      <span>{formatPrice(processingFee)}</span>
                    </p>
                    <p className="d-flex mb-0 mt-3">
                      <span>Cupons e descontos</span>
                      <span className="dot" />
                      <span className="color-desc">
                        - {formatPrice(discount)}
                      </span>
                    </p>
                    <p className="total mb-0 mt-3">
                      Total: <span>{formatPrice(total)}</span>
                    </p>
                  </div>

                  <InputCheckbox
                    type="checkbox"
                    name="terms"
                    className="mb-3 d-block check"
                    options={[
                      {
                        labelHtml: true,
                        label: (
                          <>
                            Li e estou de acordo com o{' '}
                            <button
                              type="button"
                              onClick={() => setRegulations(true)}
                            >
                              regulamento
                            </button>{' '}
                            e{' '}
                            <button type="button" onClick={() => setTerm(true)}>
                              termos de responsabilidade
                            </button>{' '}
                            deste evento.
                          </>
                        ),
                        value:
                          'Li e estou de acordo com o regulamento e termos de responsabilidade deste evento.',
                        selected: terms,
                      },
                    ]}
                    onChange={() => setTerms(!terms)}
                  />

                  <button
                    type="submit"
                    disabled={!terms}
                    className={`${
                      !terms && 'opacity-50'
                    } btn btn-login h6 fw-normal py-2 mt-3 mb-0`}
                  >
                    Continuar
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      )}
      <ModalTerms show={term} onClose={() => setTerm(false)} />
      <ModalRegulations
        show={regulations}
        onClose={() => setRegulations(false)}
      />
      <Modal show={show}>
        <Modal.Header className="d-flex align-items-center justify-content-center">
          <Modal.Title className="mb-0">Qr Code Pix</Modal.Title>
          <button
            type="button"
            onClick={() => setShow(false)}
            className="close-btn"
          >
            <KrClose size={20} color="#000" />
          </button>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
          <QRCode
            value={qrCode}
            removeQrCodeBehindLogo
            logoImage={logoQrcode}
            logoPadding={5}
            size={250}
            ecLevel="L"
          />
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center">
          <button
            type="button"
            className="copy-btn border-0 py-2 px-4 text-center mt-3"
            onClick={handleClickCopyPix}
          >
            Copiar código pix
          </button>
        </Modal.Footer>
      </Modal>
      {tickets.length > 0 && (
        <div className="opacity-0">
          {tickets.map((ticket) => (
            <Ticket key={ticket.id} ticket={ticket} />
          ))}
        </div>
      )}
    </>
  );
};

export default CheckoutPayment;
