import React, { useCallback, useEffect, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { addMonths, endOfDay, format, parseISO, startOfDay } from 'date-fns';
import { Container, ImgEvent } from './styles';
import ButtonBack from '~/components/ButtonBack';
import Footer from '~/components/Footer';
import InputSearch, { IFilter } from '~/components/InputSearch';
import { KrCalendar, KrMapMark } from '~/components/KoroIcons';

import api from '~/services/api';
import InfiniteScroll from '~/components/InfiniteScroll';

interface IArchive {
  archive_url: string;
}

interface IArchiveEvent {
  archive: IArchive;
}

interface IEvent {
  id: number;
  name: string;
  date: string;
  slug: string;
  status: {
    name: string;
  };
  address: {
    neighborhood: string;
  };
  archivesEvents: IArchiveEvent[];
}

interface IEventResponse {
  data: IEvent[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

interface ILocation {
  category?: string;
  search?: string;
}

interface IFilters {
  search?: string;
  page: number;
  startDate: Date;
  endDate: Date;
  city?: string;
  state?: string;
  category_id?: number;
  organizer_id?: number;
}

const SearchEvents: React.FC = () => {
  const location = useLocation<ILocation>();
  const [category, setCategory] = useState('');
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<IFilters>({
    page: 1,
    startDate: new Date(),
    endDate: addMonths(new Date(), 3),
  });
  const [events, setEvents] = useState<IEvent[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);

  const handleLoadEvents = useCallback(
    async ({
      search: searchData,
      page: pageData,
      startDate,
      endDate,
      city,
      state,
      category_id,
      organizer_id,
    }: IFilters) => {
      try {
        const response = await api.get<IEventResponse>('events', {
          params: {
            search: searchData,
            page: pageData,
            startDate: startOfDay(startDate),
            endDate: endOfDay(endDate),
            city,
            state,
            category_id,
            organizer_id,
            status_ids: [3, 4, 8],
            isAthlete: true,
          },
        });
        if (pageData === 1) {
          setEvents(response.data.data);
        } else {
          setEvents((oldState) => [...oldState, ...response.data.data]);
        }
        setTotalPages(response.data.pages);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoadingMore(false);
      }
    },
    []
  );

  useEffect(() => {
    handleLoadEvents(filters);
  }, [filters, handleLoadEvents]);

  useEffect(() => {
    if (location.state) {
      if (location.state.category) {
        setCategory(location.state.category);
      }

      if (location.state.search) {
        setFilters((oldState) => ({
          ...oldState,
          search: location.state.search,
        }));
        setSearch(location.state.search);
      }
    }
  }, [location.state]);

  const handleChangeFilter = useCallback((filter: IFilter) => {
    const [city, state] = filter.locale.split('/');
    setFilters((oldState) => ({
      ...oldState,
      page: 1,
      startDate: filter.startDate,
      endDate: filter.endDate,
      category_id: filter.category.id || undefined,
      organizer_id: filter.organizer.id || undefined,
      city: city === 'Brasil' ? undefined : city,
      state,
    }));
  }, []);

  const handleSearch = useCallback((searchData) => {
    setFilters((oldState) => ({
      ...oldState,
      page: 1,
      search: searchData,
    }));
  }, []);

  const handleLoad = useCallback(async () => {
    if (!loadingMore) {
      setLoadingMore(true);
      if (filters.page < totalPages) {
        setFilters((oldState) => ({
          ...oldState,
          page: oldState.page + 1,
        }));
      } else {
        setLoadingMore(false);
      }
    }
  }, [filters.page, loadingMore, totalPages]);

  return (
    <Container>
      <InfiniteScroll onInfiniteLoad={handleLoad} className="infinite-scroll">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bg-white px-3 py-3 mt-3">
                <ButtonBack className="" />

                <h1 className="mt-3 mb-3">Buscar por eventos</h1>
                <div className="d-flex">
                  <InputSearch
                    className="py-1 flex-auto input-search"
                    filter
                    filterBy
                    category={category}
                    search={search}
                    onChangeFilter={handleChangeFilter}
                    onSearch={handleSearch}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              {events.map((event) => (
                <div className="card-events flex-column d-flex mt-3">
                  <Link
                    to={`${process.env.PUBLIC_URL}/eventos/${event.slug}`}
                    className="position-relative"
                  >
                    {event.status.name !== 'Aberto' && (
                      <span className="sold-out">Inscrições encerradas</span>
                    )}
                    <ImgEvent
                      src={
                        (event.archivesEvents.length > 0 &&
                          event.archivesEvents[0].archive?.archive_url) ||
                        ''
                      }
                      alt={event.name}
                      className="w-100"
                    />
                    <span className="ms-2 mt-2">{event.name}</span>
                    <span className="ms-2">
                      <KrCalendar size={12} className="me-1" color="#878787" />
                      {format(parseISO(event.date), 'dd/MM/yyyy')}
                    </span>
                    <span className="ms-2 mb-2">
                      <KrMapMark size={12} className="me-1" color="#878787" />
                      {event.address.neighborhood}
                    </span>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </InfiniteScroll>
    </Container>
  );
};

export default SearchEvents;
