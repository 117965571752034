import React, { useCallback, useEffect, useState } from 'react';
import Input from '~/components/Input';
import InputCheckbox, { IOption } from '~/components/InputCheckbox';
import { IQuestion } from '~/pages/Ticket';
import { Container } from './styles';

export interface IAnswer {
  id: number;
  question_type_id: number;
  question_id: number;
  alternative_id: number;
  text: string;
}

interface IQuestionProps {
  question: IQuestion;
  ticketIndex?: number;
  onChangeAnswer: (answer: IAnswer[]) => void;
}

const Question: React.FC<IQuestionProps> = ({
  question,
  ticketIndex,
  onChangeAnswer,
}) => {
  const [alternatives, setAlternatives] = useState<IOption[]>([]);

  useEffect(() => {
    const data = question.alternatives?.map((alternativesData) => ({
      label: alternativesData.text,
      value: alternativesData.id,
      selected: false,
    }));
    if (data) {
      setAlternatives(data);
    }
  }, [question.alternatives]);

  const handleChangeAnswer = useCallback(
    (answerData: IAnswer[]) => {
      onChangeAnswer(answerData);
    },
    [onChangeAnswer]
  );

  const handleSelectAlternative = useCallback(
    (alternativesData: IOption[]) => {
      if (question.type === 'unique-choice') {
        const answerData = alternativesData.find(
          (alternative) => alternative.selected
        );

        if (answerData) {
          const dataAnswer: IAnswer = {
            id: new Date().getTime(),
            question_type_id: 2,
            question_id: question.id,
            alternative_id: answerData.value as number,
            text: answerData.label as string,
          };
          handleChangeAnswer([dataAnswer]);
        }
      }

      if (question.type === 'multiple-choice') {
        const answersData = alternativesData.filter(
          (alternative) => alternative.selected
        );

        let dataAnswer: IAnswer[] = [];

        if (answersData.length > 0) {
          dataAnswer = answersData.map((answer) => ({
            id: new Date().getTime(),
            question_type_id: 3,
            question_id: question.id,
            alternative_id: answer.value as number,
            text: answer.label as string,
          }));
        }
        handleChangeAnswer(dataAnswer);
      }

      setAlternatives(alternativesData);
    },
    [handleChangeAnswer, question.id, question.type]
  );

  return (
    <Container className="bg-white p-3 mb-2">
      <label className="mb-3 w-100">
        <span className="fw-normal d-block mb-2">{question.question}</span>
        {question.type === 'free-text' && (
          <Input
            name={`answer-text-${question.id}${
              typeof ticketIndex === 'number' ? `-${ticketIndex}` : ''
            }`}
            placeholder="Digite aqui"
            className="bd-input"
            onChange={(e) =>
              handleChangeAnswer([
                {
                  id: new Date().getTime(),
                  question_type_id: 1,
                  question_id: question.id,
                  alternative_id: question.alternatives
                    ? question.alternatives[0].id
                    : 0,
                  text: e.target.value,
                },
              ])
            }
          />
        )}

        {question.type === 'unique-choice' && (
          <InputCheckbox
            type="radio"
            name={`answer-radio-${question.id}${
              typeof ticketIndex === 'number' ? `-${ticketIndex}` : ''
            }`}
            className="d-block input-radio"
            options={alternatives}
            onChange={(options) => handleSelectAlternative(options)}
          />
        )}

        {question.type === 'multiple-choice' && (
          <InputCheckbox
            type="checkbox"
            name={`answer-multiple-${question.id}${
              typeof ticketIndex === 'number' ? `-${ticketIndex}` : ''
            }`}
            className="d-block input-checkbox"
            options={alternatives}
            onChange={(options) => handleSelectAlternative(options)}
          />
        )}
      </label>
    </Container>
  );
};

export default Question;
