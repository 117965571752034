import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

interface IAnswer {
  event_id: number;
  question_type_id: number;
  question_id: number;
  alternative_id: number;
  text: string;
}

interface ITicket {
  id: number;
  document?: string;
  name?: string;
  phone?: string;
  email?: string;
  answers?: IAnswer[];
  kit?: {
    event_id: number;
    kit_id: number;
    user_id: number;
    ticket_id?: number;
    color_id?: number;
    size_id?: number;
  };
}

export interface IItem {
  batch_category_id: number;
  modality_id: number;
  modality: string;
  category: string;
  value: number;
  quantity: number;
  ticket?: ITicket;
}

export interface ICart {
  items: IItem[];
  subtotal: number;
  isFillBeforeTicket?: boolean;
}

interface CartContextData {
  cart: ICart;
  handleChangeItems(items: IItem[]): void;
  setFillBefore: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CartContext = createContext<CartContextData>(
  {} as CartContextData
);

export const CartProvider: React.FC = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const data = localStorage.getItem('@Koro:cart');

    if (data) {
      return JSON.parse(data) as ICart;
    }

    return {} as ICart;
  });

  const [fillBefore, setFillBefore] = useState(false);

  const handleChangeItems = useCallback(
    (items: IItem[]) => {
      const subtotal = items.reduce((previous, current) => {
        return previous + current.value;
      }, 0);

      const data = {
        items,
        subtotal,
        isFillBeforeTicket: cart.isFillBeforeTicket,
      };

      localStorage.setItem('@Koro:cart', JSON.stringify(data));

      setCart(data);
    },
    [cart.isFillBeforeTicket]
  );

  useEffect(() => {
    setCart((oldState) => ({
      ...oldState,
      isFillBeforeTicket: fillBefore,
    }));
  }, [fillBefore]);

  return (
    <CartContext.Provider value={{ cart, handleChangeItems, setFillBefore }}>
      {children}
    </CartContext.Provider>
  );
};

export function useCart(): CartContextData {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error('useCart must be used within an CartProvider');
  }

  return context;
}
