import React, { useCallback, useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { addHours, format, parseISO } from 'date-fns';
import { Category, Container, Hero, ImgEvent, LastEvents } from './styles';

import hero from '~/assets/banners/hero-home-new.png';
import Header from '~/components/Header';
import { KrCalendar, KrMapMark } from '~/components/KoroIcons';

import Footer from '~/components/Footer';
import api from '~/services/api';
import InfiniteScroll from '~/components/InfiniteScroll';

interface IArchive {
  archive_url: string;
}

interface IArchiveEvent {
  archive: IArchive;
}

interface IEvent {
  id: number;
  name: string;
  date: string;
  slug: string;
  status: {
    name: string;
  };
  address: {
    neighborhood: string;
  };
  archivesEvents: IArchiveEvent[];
}

interface IEventResponse {
  data: IEvent[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

interface ICategory {
  id: number;
  name: string;
  events: IEvent[];
  archive: IArchive;
}

interface ICategoryResponse {
  data: ICategory[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

const Home: React.FC = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [lastestEvents, setLastestEvents] = useState<IEvent[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);

  const handleLoadLastestEvents = useCallback(async () => {
    const response = await api.get<IEventResponse>('events/lastest', {
      params: {
        page: 1,
        status: ['Aberto', 'Esgotado', 'Fechado'],
      },
    });
    setLastestEvents(response.data.data);
  }, []);

  const handleLoadCategories = useCallback(async (pageData) => {
    try {
      const response = await api.get<ICategoryResponse>('events-types', {
        params: {
          page: pageData,
          status: ['Aberto', 'Esgotado', 'Fechado'],
        },
      });
      if (pageData === 1) {
        setCategories(response.data.data);
      } else {
        setCategories((state) => [...state, ...response.data.data]);
      }
      setTotalPages(response.data.pages);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoadingMore(false);
    }
  }, []);

  useEffect(() => {
    handleLoadLastestEvents();
    handleLoadCategories(1);
  }, [handleLoadCategories, handleLoadLastestEvents]);

  const handleCategory = useCallback(
    (category) => {
      if (category === 'lastest') {
        history.push(`${process.env.PUBLIC_URL}/buscar-eventos`);
      } else {
        history.push(`${process.env.PUBLIC_URL}/buscar-eventos`, {
          category,
        });
      }
    },
    [history]
  );

  const handleLoad = useCallback(async () => {
    if (!loadingMore) {
      setLoadingMore(true);
      if (page < totalPages) {
        await handleLoadCategories(page + 1);
        setPage(page + 1);
      } else {
        setLoadingMore(false);
      }
    }
  }, [handleLoadCategories, page, totalPages, loadingMore]);

  return (
    <Container className="position-relative">
      <InfiniteScroll onInfiniteLoad={handleLoad} className="infinite-scroll">
        <Header />
        <Hero>
          <div className="container">
            <div className="row">
              <div className="col-12 px-3">
                <img src={hero} alt="Banner hero" className="w-100" />
                <hr className="my-4" />
              </div>
            </div>
          </div>
        </Hero>

        <Category>
          <div className="container">
            <div className="row">
              <div className="col-12 px-3 mb-3">
                <h2>Esportes</h2>
              </div>
            </div>
          </div>
          <InfiniteScroll
            onInfiniteLoad={handleLoad}
            direction="horizontal"
            className="overflow-auto"
          >
            <div className="d-flex w-categories w-sm-100">
              {categories.map((category) => (
                <div>
                  <button
                    type="button"
                    onClick={() => handleCategory(category.name)}
                    className="mx-3 bg-transparent border-0"
                  >
                    <img src={category.archive.archive_url} alt="Categoria" />

                    <span className="d-block pt-2">{category.name}</span>
                  </button>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </Category>

        <LastEvents>
          <div className="container">
            <div className="row">
              <div className="col-12 px-3 mt-5">
                <h2 className="mb-0">Últimos eventos</h2>
                <button
                  type="button"
                  onClick={() => handleCategory('lastest')}
                  className="view-all d-flex justify-content-end mt-n2 mb-3 ms-auto border-0 bg-transparent"
                >
                  Ver todos
                </button>
              </div>
            </div>
          </div>

          <div className="d-flex overflow-auto">
            {lastestEvents.map((event) => (
              <div className="card-events flex-column d-flex ">
                <Link
                  to={`${process.env.PUBLIC_URL}/eventos/${event.slug}`}
                  className="mx-3 position-relative"
                >
                  {event.status.name !== 'Aberto' && (
                    <span className="sold-out">Inscrições encerradas</span>
                  )}
                  {event.status.name === 'Em Alteração' && (
                    <span className="suspended">
                      Vendas Suspensas Temporariamente
                    </span>
                  )}
                  <ImgEvent
                    src={
                      (event.archivesEvents.length > 0 &&
                        event.archivesEvents[0].archive?.archive_url) ||
                      ''
                    }
                  />
                  <span className="ms-2 mt-2">{event.name}</span>
                  <span className="ms-2">
                    <KrCalendar size={12} className="me-1" />
                    {format(addHours(parseISO(event.date), 3), 'dd/MM/yyyy')}
                  </span>

                  <span className="ms-2 mb-2">
                    <KrMapMark size={12} className="me-1" />
                    {event.address.neighborhood}
                  </span>
                </Link>
              </div>
            ))}
          </div>

          {categories.map((category) => (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-12 px-3 mt-5">
                    <h2 className="mb-0">{category.name}</h2>
                    <button
                      type="button"
                      onClick={() => handleCategory(category.name)}
                      className="view-all d-flex justify-content-end mt-n2 mb-3 ms-auto border-0 bg-transparent"
                    >
                      Ver todos
                    </button>
                  </div>
                </div>
              </div>

              <div className="d-flex overflow-auto">
                {category.events.map((event) => (
                  <div className="card-events flex-column d-flex">
                    <Link
                      to={`${process.env.PUBLIC_URL}/eventos/${event.slug}`}
                      className="mx-3 position-relative"
                    >
                      {event.status.name !== 'Aberto' && (
                        <span className="sold-out">Inscrições encerradas</span>
                      )}
                      {event.status.name === 'Em Alteração' && (
                        <span className="suspended">
                          Vendas Suspensas Temporariamente
                        </span>
                      )}
                      <ImgEvent
                        src={
                          (event.archivesEvents.length > 0 &&
                            event.archivesEvents[0].archive?.archive_url) ||
                          ''
                        }
                      />
                      <span className="ms-2 mt-2">{event.name}</span>
                      <span className="ms-2">
                        <KrCalendar size={12} className="me-1" />
                        {format(
                          addHours(parseISO(event.date), 3),
                          'dd/MM/yyyy'
                        )}
                      </span>
                      <span className="ms-2 mb-2">
                        <KrMapMark size={12} className="me-1" />
                        {event.address.neighborhood}
                      </span>
                    </Link>
                  </div>
                ))}
              </div>
            </>
          ))}
        </LastEvents>
        <Footer />
      </InfiniteScroll>
    </Container>
  );
};

export default Home;
