/* eslint-disable no-param-reassign */
/* eslint-disable no-loop-func */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import { cpf } from 'cpf-cnpj-validator';

import { useHistory, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Container } from './styles';
import { KrUserDocument } from '~/components/KoroIcons';
import ButtonBack from '~/components/ButtonBack';
import { IOption } from '~/components/Select';
import { useAuth } from '~/hooks/Auth';
import { IItem, useCart } from '~/hooks/Cart';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Ticket, { IAnswer, IQuestionResponse } from './Ticket';

interface ISize {
  id: number;
  size: string;
}

interface IProduct {
  sizes: ISize[];
}

export interface ITicket {
  myTicket?: boolean;
  index: number;
  item: IItem;
  document?: string;
  name?: string;
  phone?: string;
  email?: string;
  answers?: IAnswer[];
}

interface IParams {
  slug: string;
}

interface IFormData {
  document: string;
  name: string;
  phone: string;
  email: string;
  documents: string;
  color: string;
  size: string;
}

interface ISizeColor {
  id: number;
  color: string;
  selected: boolean;
  sizes: IOption[];
}

export interface IProductColorSize {
  id: number;
  kit_id: number;
  product: string;
  colors: IOption[];
  colorSizes: ISizeColor[];
}

interface IQuantity {
  id: number;
  size_id?: number;
  color_id?: number;
  quantity: number;
  color?: IColor;
  size: ISize;
}

interface IProduct {
  id: number;
  name: string;
  quantities: IQuantity[];
}

interface IKitResponse {
  id: number;
  products: IProduct[];
}

interface IEventResponse {
  id: number;
  kits: IKitResponse[];
}

interface IColor {
  id: number;
  name: string;
  color: string;
}

interface IKit {
  event_id: number;
  kit_id: number;
  user_id: number;
  ticket_id?: number;
  color_id?: number;
  size_id?: number;
}

const CheckoutOwnership: React.FC = () => {
  const { user } = useAuth();
  const { cart, handleChangeItems } = useCart();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const params = useParams<IParams>();
  const [indexSel, setIndexSel] = useState<number>(-1);
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [eventId, setEventId] = useState<number | undefined>();
  const [sending, setSending] = useState(false);
  const [productsArray, setProductsArray] = useState<IProductColorSize[]>([]);
  const [, setTicketError] = useState('');

  useEffect(() => {
    api.get<IEventResponse>(`events/${params.slug}`).then((response) => {
      if (response.data.kits) {
        for (const kit of response.data.kits) {
          const products = kit.products.filter((productData) =>
            productData.quantities.some((quantity) => quantity.size_id)
          );

          if (products.length > 0) {
            let index = 0;
            for (const product of products) {
              const colorSizesAux = product.quantities.reduce(
                (previous, current) => {
                  const colorId = current.color_id || index;

                  if (previous[colorId]) {
                    previous[colorId].sizes.push({
                      id: current.size.id,
                      value: current.size.size,
                      selected: false,
                    });
                  } else {
                    previous[colorId] = {
                      id: colorId,
                      color: current.color?.color || '',
                      selected: false,
                      sizes: [
                        {
                          id: current.size.id,
                          value: current.size.size,
                          selected: false,
                        },
                      ],
                    };
                  }

                  return previous;
                },
                {} as any
              );
              const colors = product.quantities
                .map((quantity) => ({
                  id: quantity.color?.id || '',
                  value: quantity.color?.color || '',
                  color: quantity.color?.name,
                  selected: false,
                }))
                .filter(
                  (obj, idx, self) =>
                    idx === self.findIndex((t) => t.id === obj.id)
                );
              setProductsArray((prevState) => [
                ...prevState,
                {
                  id: product.id,
                  kit_id: kit.id,
                  product: product.name,
                  colors: colors.filter((color) => color.id),
                  colorSizes: Object.values(colorSizesAux),
                },
              ]);

              index += 1;
            }
          }
        }
      }
      setEventId(response.data.id);
    });
  }, [params.slug]);

  useEffect(() => {
    const data = cart.items.map<ITicket>((item, index) => ({
      index,
      item,
      myTicket: item.ticket?.document === user.additionalInformation.document,
      document: item.ticket?.document,
      name: item.ticket?.name,
      phone: item.ticket?.phone,
      email: item.ticket?.email,
    }));

    setTickets(data);
    setIndexSel(data.findIndex((ticket) => ticket.myTicket));
  }, [cart, cart.items, user.additionalInformation.document]);

  const handleChangeMyTicket = useCallback(
    (ticket) => {
      const newTickets = tickets.slice();
      newTickets[ticket.index].myTicket = !newTickets[ticket.index].myTicket;

      if (!newTickets[ticket.index].myTicket) {
        newTickets[ticket.index].document =
          newTickets[ticket.index].document || '';
        newTickets[ticket.index].name = newTickets[ticket.index].name || '';
        newTickets[ticket.index].email = newTickets[ticket.index].email || '';
        newTickets[ticket.index].phone = newTickets[ticket.index].phone || '';
        setIndexSel(-1);
      } else {
        newTickets[ticket.index].document =
          user.additionalInformation.document ||
          newTickets[ticket.index].document;
        newTickets[ticket.index].name = user.name;
        newTickets[ticket.index].email = user.email;
        newTickets[ticket.index].phone =
          user.additionalInformation.phone || newTickets[ticket.index].phone;
        setIndexSel(ticket.index);
      }
      setTickets(newTickets);
    },
    [tickets, user]
  );

  const handleChangeTicket = useCallback(
    (ticket) => {
      const newTickets = tickets.slice();
      newTickets[ticket.index] = ticket;

      setTickets(newTickets);
    },
    [tickets]
  );

  const handleChangeAnswers = useCallback(
    (answers, ticketIndex) => {
      const newTickets = tickets.slice();
      newTickets[ticketIndex].answers = answers;
      setTickets(newTickets);
    },
    [tickets]
  );

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setSending(true);
        formRef.current?.setErrors({});

        const responseQuestions = await api.get<IQuestionResponse[]>(
          `questions/events/${eventId}`
        );

        const schema = Yup.object().shape({
          ...cart.items.reduce((previous, item, index) => {
            const colors = {} as {
              [key: string]: Yup.StringSchema<
                string | undefined,
                Record<string, any>,
                string | undefined
              >;
            };
            const colorSizes = {} as {
              [key: string]: Yup.StringSchema<
                string | undefined,
                Record<string, any>,
                string | undefined
              >;
            };
            if (productsArray.length > 0) {
              productsArray.forEach((product, idx) => {
                if (product.colors && product.colors.length > 0) {
                  colors[`color_${index}_${idx}`] = Yup.string().required(
                    'A cor é obrigatória'
                  );
                }
                if (product.colorSizes.length > 0) {
                  product.colorSizes.forEach((colorSize) => {
                    if (colorSize.color && colorSize.selected) {
                      colorSizes[`size_${index}_${idx}`] =
                        Yup.string().required('O tamanho é obrigatório');
                    }
                  });
                }
              });
            }

            return {
              ...previous,
              [`document_${index}`]: Yup.string()
                .nullable(true)
                .test('check_document', 'O documento é obrigatório', () => {
                  return !!data[`document_${index}` as keyof IFormData];
                })
                .test('cpf', 'CPF inválido', (value) =>
                  cpf.isValid(value as string)
                ),
              [`name_${index}`]: Yup.string().test(
                'check_name',
                'O nome é obrigatório',
                () => {
                  return !!data[`name_${index}` as keyof IFormData];
                }
              ),
              [`phone_${index}`]: Yup.string().test(
                'check_name',
                'O telefone é obrigatório',
                () => {
                  return !!data[`phone_${index}` as keyof IFormData];
                }
              ),
              [`email_${index}`]: Yup.string().test(
                'check_name',
                'O e-mail é obrigatório',
                () => {
                  return !!data[`email_${index}` as keyof IFormData];
                }
              ),
              ...responseQuestions.data.reduce((subPrevious, question) => {
                if (question.required) {
                  if (question.question_type_id === 1) {
                    subPrevious[`answer-text-${question.id}-${index}`] =
                      Yup.string().when(`$checkText-${question.id}`, {
                        is: (checkText: boolean) => checkText,
                        then: Yup.string().required('A resposta é obrigatória'),
                        otherwise: Yup.string(),
                      });
                  }
                  if (question.question_type_id === 2) {
                    subPrevious[`answer-radio-${question.id}-${index}`] =
                      Yup.string().when(`$checkRadio-${question.id}`, {
                        is: (checkRadio: boolean) => checkRadio,
                        then: Yup.string().required('A resposta é obrigatória'),
                        otherwise: Yup.string(),
                      });
                  }
                  if (question.question_type_id === 3) {
                    subPrevious[`answer-multiple-${question.id}-${index}`] =
                      Yup.string().when(`$checkMultiple-${question.id}`, {
                        is: (checkMultiple: boolean) => checkMultiple,
                        then: Yup.string().required('A resposta é obrigatória'),
                        otherwise: Yup.string(),
                      });
                  }
                }

                return subPrevious;
              }, {} as { [key: string]: Yup.StringSchema<string | undefined, Record<string, any>, string | undefined> }),
              ...colors,
              ...colorSizes,
            };
          }, {} as { [key: string]: any }),
        });

        // const duplicate = findSameDocument(
        //     ticket.item.ticket?.document || ticket.document,
        //     i
        //   );

        //   if (duplicate) {
        //     newTickets[i].error = {
        //       document:
        //         ticket.document === user.additionalInformation.document ||
        //         ticket.item.ticket?.document ===
        //           user.additionalInformation.document
        //           ? 'Você já preencheu um ingresso com teus dados'
        //           : 'Um ingresso já foi preenchido com esses dados',
        //     };
        //   }

        await schema.validate(data, {
          abortEarly: false,
          context: {
            ...responseQuestions.data.reduce((previous, question) => {
              if (question.required) {
                if (question.question_type_id === 1) {
                  previous[`checkText-${question.id}`] = question.required;
                }
                if (question.question_type_id === 2) {
                  previous[`checkRadio-${question.id}`] = question.required;
                }
                if (question.question_type_id === 3) {
                  previous[`checkMultiple-${question.id}`] = question.required;
                }
              }

              return previous;
            }, {} as { [key: string]: boolean }),
          },
        });

        const items: IItem[] = [];

        let index = 0;

        for (const ticket of tickets) {
          let color_id: number | undefined;
          let size_id: number | undefined;
          let idx = 0;
          let kit: IKit | undefined;
          if (productsArray.length > 0) {
            for (const product of productsArray) {
              if (product.colors && product.colors.length > 0) {
                color_id = parseInt(
                  data[`color_${index}_${idx}` as keyof IFormData],
                  10
                );
              }
              if (product.colorSizes.length > 0) {
                size_id = parseInt(
                  data[`size_${index}_${idx}` as keyof IFormData],
                  10
                );
              }
              idx += 1;
            }

            kit = {
              event_id: eventId as number,
              kit_id: productsArray[0].kit_id,
              user_id: user.id,
              color_id,
              size_id,
            } as IKit;
          }

          items.push({
            ...ticket.item,
            ticket: {
              id: new Date().getTime(),
              document: data[`document_${index}` as keyof IFormData],
              name: data[`name_${index}` as keyof IFormData],
              phone: data[`phone_${index}` as keyof IFormData],
              email: data[`email_${index}` as keyof IFormData],
              answers: ticket.answers,
              kit,
            },
          });

          index += 1;
        }

        handleChangeItems(items);

        history.push(`${process.env.PUBLIC_URL}/eventos/${params.slug}/resumo`);

        setSending(false);
      } catch (error) {
        setSending(false);
        const err = error as any;
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);

          formRef.current?.setErrors(errors);
        } else if (err.message === 'ingresso repetido') {
          setTicketError(
            data.document === user.additionalInformation.document
              ? 'Você já possui um ingresso para este evento'
              : 'Já existe um ingresso com este CPF para este evento'
          );
        } else {
          Swal.fire(
            'Oops...',
            'Ocorreu um erro tente novamente, por favor',
            'error'
          );
        }
      }
    },
    [
      cart.items,
      eventId,
      handleChangeItems,
      history,
      params.slug,
      productsArray,
      tickets,
      user.additionalInformation.document,
      user.id,
    ]
  );

  const handleChangeColor = useCallback((option, idx) => {
    setProductsArray((oldState) => {
      const products = oldState.slice();
      products[idx].colorSizes = products[idx].colorSizes.map((colorSize) => {
        return {
          ...colorSize,
          selected: colorSize.color === option.value,
        };
      });
      return products;
    });
  }, []);

  return (
    <Container>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-11 text-center pt-3">
            <ButtonBack className="btn-back" />
            <div className="banner my-3" />
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h2 className="h4 mb-0 d-flex align-items-center">
                <KrUserDocument
                  color="#FF6900"
                  size={20}
                  className="me-2 signin-icon"
                />
                Titularidade
              </h2>
            </div>
            <h4 className="text-start mb-3">
              Para seguir para o pagamento por favor preencha os dados dos
              ingressos
            </h4>
          </div>

          <div className="col-11">
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              className="d-flex flex-column pb-3"
            >
              {tickets.map((ticket) => (
                <Ticket
                  ticket={ticket}
                  myTicketIndex={indexSel}
                  productsArray={productsArray}
                  onChangeMyTicket={handleChangeMyTicket}
                  onChangeTicket={handleChangeTicket}
                  onChangeAnswers={(answers) => {
                    handleChangeAnswers(answers, ticket.index);
                  }}
                  onChangeColor={handleChangeColor}
                  eventId={eventId}
                />
              ))}
              <button
                type="submit"
                disabled={sending}
                className="btn btn-login h6 fw-normal py-2 mb-0"
              >
                Continuar
              </button>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CheckoutOwnership;
