import React, { useCallback, useEffect, useState } from 'react';

import { format, parseISO } from 'date-fns';
import { IoClose } from 'react-icons/io5';
import { Container, Modal } from './styles';
import InputSearch from '~/components/InputSearch';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import KrDoubt from '~/components/KoroIcons/KrDoubt';
import { useAuth } from '~/hooks/Auth';

interface IItem {
  id: number;
  quantity: number;
  batch_category_id: number;
  ticket: {
    modality: {
      name: string;
    };
  };
  price: number;
  batchCategory: {
    price: string;
    batch: {
      event: {
        name: string;
      };
    };
    modality: {
      name: string;
    };
    category: {
      name: string;
    };
  };
}

interface IOrder {
  id: number;
  code: string;
  service_fee: string;
  processing_fee: string;
  discount: string;
  total: number;
  installments: number;
  status: string;
  created_at: string;
  items: IItem[];
  quantity: number;
  payment: {
    payment_type: string;
  };
}

interface IOrderResponse {
  data: IOrder[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

const Purchases: React.FC = () => {
  const { user } = useAuth();
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [page, setPage] = useState(1);
  const [indexSel, setIndexSel] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [show, setShow] = useState(false);
  const [itemsSel, setItemsSel] = useState<IItem[]>([]);

  const handleLoadOrders = useCallback(async (pageData) => {
    const response = await api.get<IOrderResponse>('orders/purchases', {
      params: {
        page: pageData,
      },
    });

    // AJUSTAR QUANTIDADE DE ITEMS
    const data = response.data.data.map((order) => {
      return {
        ...order,
        quantity: order.items.length,
        status: order.status,
      };
    });

    if (pageData === 1) {
      setOrders(data);
    } else {
      setOrders((state) => [...state, ...data]);
    }

    setTotalPages(response.data.pages);
  }, []);

  const handlePurchase = useCallback(
    (index) => {
      setShow(true);
      setIndexSel(index);

      const items = orders[index].items.reduce((previous, current) => {
        const newData = previous;
        const itemSelected = newData.find(
          (item) => item.batch_category_id === current.batch_category_id
        );
        if (!itemSelected) {
          newData.push(current);
        }
        return newData;
      }, [] as IItem[]);
      setItemsSel(items);
    },
    [orders]
  );

  useEffect(() => {
    handleLoadOrders(1);
  }, [handleLoadOrders]);

  const handleClickLoadMore = useCallback(() => {
    if (page < totalPages) {
      handleLoadOrders(page + 1);
      setPage(page + 1);
    }
  }, [handleLoadOrders, page, totalPages]);

  return (
    <>
      <Container className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="bg p-3 mt-3">
              <div className="d-flex">
                <InputSearch className="py-1 flex-auto" filter />
              </div>
              {orders.map((order, index) => (
                <button
                  type="button"
                  onClick={() => handlePurchase(index)}
                  className={`border-0 w-100 d-flex justify-content-between mt-3 pt-2 pb-3 ${
                    index !== orders.length - 1 && 'bd-bottom'
                  }`}
                >
                  <div className="text-start">
                    <h3 className="mb-0">#{order.code}</h3>

                    <h3 className="h6 mb-0">
                      {order.items[0].batchCategory.batch.event.name}
                    </h3>

                    <p className="mb-0">
                      {format(
                        parseISO(order.created_at),
                        "dd/MM/yyyy 'às' HH:mm"
                      )}
                    </p>

                    <p className="mb-0">
                      {order.quantity} unidade{order.quantity !== 1 ? 's' : ''}{' '}
                      - {order.status}
                    </p>
                  </div>
                  <div className="value">
                    {order.installments > 1 && (
                      <span>{order.installments}x</span>
                    )}{' '}
                    {formatPrice(order.total)}
                  </div>
                </button>
              ))}
              {page < totalPages && (
                <button
                  type="button"
                  className="w-100 border-0 bg-transparent text-primary small mt-2"
                  onClick={handleClickLoadMore}
                >
                  Carregar mais...
                </button>
              )}
            </div>
          </div>
        </div>
      </Container>

      <Modal
        className="modal-filter"
        size="xl"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header className="justify-content-between align-items-center border-0 px-3 pb-0 pt-0">
          <Modal.Title className="mb-3 mt-4" />
          <button
            type="button"
            onClick={() => setShow(false)}
            className="border-0"
          >
            <IoClose size={20} color="#989898" />
          </button>
        </Modal.Header>
        <Modal.Body className="pt-0 px-3 px-sm-5">
          <h3 className="d-flex align-items-center h4 text-black">
            {orders[indexSel]?.items[0].batchCategory.batch.event.name}
          </h3>
          <h5 className="d-flex align-items-center h6 text-black">
            Pedido: #{orders[indexSel]?.code}
          </h5>
          <h5 className="d-flex align-items-center h6 text-black">
            Status Pagamento: {orders[indexSel]?.status}
          </h5>
          <h5 className="d-flex align-items-center h6 text-black">
            Tipo de pagamento:{' '}
            {orders[indexSel]?.payment?.payment_type === 'credit-card' ||
            orders[indexSel]?.payment?.payment_type === 'credit_card'
              ? 'Cartão de Crédito'
              : 'PIX'}
          </h5>
          <div className="border-ticket values p-3">
            <h3 className="d-flex align-items-center h6 text-black">
              Resumo de valores
            </h3>

            {itemsSel.map((item) => (
              <p className="d-flex mb-0 mt-3">
                <span>
                  {item.quantity}x {item.batchCategory.modality?.name} -{' '}
                  {item.batchCategory.category.name}
                </span>
                <span className="dot" />
                <span>{formatPrice(item.quantity * item.price)}</span>
              </p>
            ))}

            <p className="d-flex mb-0 mt-3">
              <span>Taxa de serviço</span>
              <span className="dot" />
              <span>
                {formatPrice(parseFloat(orders[indexSel]?.service_fee) || 0)}
              </span>
            </p>
            <p className="d-flex mb-0 mt-3">
              <span>
                Taxa de processamento
                <button
                  type="button"
                  className="tooltip-tax border-0 bg-transparent ms-1"
                  data-tooltip="A taxa de processamento é o custo que será repassado ao serviço de transação online para operacionalizar o pagamento"
                >
                  <KrDoubt size={16} color="#8C8C8C" />
                </button>
              </span>
              <span className="dot" />
              <span>
                {formatPrice(parseFloat(orders[indexSel]?.processing_fee) || 0)}
              </span>
            </p>
            <p className="d-flex mb-0 mt-3">
              <span>Cupons e descontos</span>
              <span className="dot" />
              <span className="color-desc">
                - {formatPrice(parseFloat(orders[indexSel]?.discount) || 0)}
              </span>
            </p>
            <p className="total mb-0 mt-3">
              Total: <span> {formatPrice(orders[indexSel]?.total)}</span>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 px-5" />
      </Modal>
    </>
  );
};

export default Purchases;
