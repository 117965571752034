/* eslint-disable no-await-in-loop */
/* eslint-disable no-loop-func */
/* eslint-disable no-param-reassign */
/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom';
import { IoClose, IoShareSocialSharp } from 'react-icons/io5';
import { FiEye } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { QRCode } from 'react-qrcode-logo';
import { cpf } from 'cpf-cnpj-validator';

import { format, parseISO } from 'date-fns';
import { BtnRevealQrCode, Container, Modal } from './styles';
import ButtonBack from '~/components/ButtonBack';
import Footer from '~/components/Footer';

import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';
import InfiniteScroll from '~/components/InfiniteScroll';
import InputCheckbox from '~/components/InputCheckbox';
import Input from '~/components/Input';
import Select, { IOption } from '~/components/Select';
import InputMask from '~/components/InputMask';
import getValidationErros from '~/utils/getValidationsErrors';
import { useResize } from '~/hooks/Resize';

import logo from '~/assets/logos/logo.png';
import logoQrcode from '~/assets/logos/logo-qrcode.svg';
import Toast from '~/utils/toast';
import { KrClose } from '~/components/KoroIcons';
import Question from '~/components/Question';

interface IEvent {
  name: string;
  date: string;
  address: string;
  description: string;
}

interface ITicketData {
  id: number;
  user_id: number;
  code: number;
  document: string;
  email: string;
  name: string;
  status: string;
  modality?: {
    id: number;
    name: string;
  };
  batchCategory: {
    id: number;
    batch: {
      event: {
        id: number;
        name: string;
        description: string;
        date: string;
        type: {
          name: string;
        };
        event: {
          date: string;
        };
        address: {
          street: string;
          number: number;
          neighborhood: string;
          city: string;
          state: string;
          complement: string;
          zip_code: string;
        };
      };
    };
    category: {
      name: string;
    };
  };
  orderItem: {
    order: {
      status: string;
      code: number;
      payment: {
        qr_code?: string;
        payment_type: string;
      };
      created_at: string;
    };
  };
  kitUser?: {
    color?: {
      name: string;
    };
    size?: {
      size: string;
    };
  };
}

interface ITicketResponse {
  data: ITicketData[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

interface ITicket {
  id: number;
  batch_category_id: number;
  modality_id?: number;
  event_id: number;
  user_id: number;
  orderCode: number;
  code: number;
  event: string;
  event_date: string;
  myTicket: boolean;
  reveled: boolean;
  name: string;
  phone?: string;
  email?: string;
  document: string;
  birthdate?: string;
  category: string;
  modality: string;
  address: string;
  status: string;
  kit?: {
    color?: string;
    size?: string;
  };
}

interface IColor {
  id: number;
  name: string;
  color: string;
}

interface ISize {
  id: number;
  size: string;
}

interface IQuantity {
  id: number;
  size_id?: number;
  color_id?: number;
  quantity: number;
  color?: IColor;
  size: ISize;
}

interface IProduct {
  id: number;
  name: string;
  quantities: IQuantity[];
}

interface IKit {
  id: number;
  products: IProduct[];
}

interface IEventResponse {
  id: number;
  kits: IKit[];
}

interface IParams {
  slug: string;
}

interface IFormData {
  document: string;
  name: string;
  phone: string;
  email: string;
  documents: string;
  color: string;
  size: string;
}

interface IOrderData {
  status: string;
  paymentMethod: string;
  qrCode?: string;
  tickets: ITicket[];
  created_at: string;
}

interface IOrder {
  [key: string]: IOrderData;
}

interface ISizeColor {
  id: number;
  color: string;
  selected: boolean;
  sizes: IOption[];
}

interface IProductColorSize {
  id: number;
  kit_id: number;
  product: string;
  colors: IOption[];
  colorSizes: ISizeColor[];
}

export interface IAlternative {
  id: number;
  text: string;
}

export interface IQuestion {
  id: number;
  question: string;
  type: 'unique-choice' | 'multiple-choice' | 'free-text';
  alternatives?: IAlternative[];
  isRequired?: boolean;
}

interface IAlternativesResponse {
  id: number;
  name: string;
}

export interface IQuestionResponse {
  id: number;
  event_id: number;
  question_type_id: number;
  name: string;
  order: number;
  required: boolean;
  alternatives: IAlternativesResponse[];
}

interface IAnswer {
  event_id: number;
  question_type_id: number;
  question_id: number;
  alternative_id: number;
  text: string;
}

const Ticket: React.FC = () => {
  const { user, updateUser } = useAuth();
  const { width } = useResize();
  const params = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [orders, setOrders] = useState({} as IOrder);
  const [ticketsData, setTicketsData] = useState<ITicketData[]>([]);
  const [ticketSelected, setTicketSelected] = useState({} as ITicket);
  const [show, setShow] = useState(false);
  const [myTicket, setMyTicket] = useState(false);
  const [eventId, setEventId] = useState(0);
  const [event, setEvent] = useState({} as IEvent);
  const [ticketError, setTicketError] = useState('');
  const [productsArray, setProductsArray] = useState<IProductColorSize[]>([]);
  const [orderSelected, setOrderSelected] = useState({} as IOrderData);
  const [showPix, setShowPix] = useState(false);
  const [sending, setSending] = useState(false);
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [answers, setAnswers] = useState<IAnswer[]>([]);

  useEffect(() => {
    if (eventId) {
      api
        .get<IQuestionResponse[]>(`questions/events/${eventId}`)
        .then((response) => {
          const data = response.data.map<IQuestion>((question) => {
            let type = 'free-text';

            if (question.question_type_id === 2) {
              type = 'unique-choice';
            }

            if (question.question_type_id === 3) {
              type = 'multiple-choice';
            }

            const alternatives = question.alternatives.map((alternative) => ({
              id: alternative.id,
              text: alternative.name,
              new: false,
            }));

            return {
              id: question.id,
              question: question.name,
              type: type as IQuestion['type'],
              order: question.order,
              alternatives,
              isRequired: question.required,
            };
          });
          setQuestions(data);
        });
    }
  }, [eventId]);

  useEffect(() => {
    api.get<IEventResponse>(`events/${params.slug}`).then((response) => {
      if (response.data.kits) {
        for (const kit of response.data.kits) {
          const products = kit.products.filter((productData) =>
            productData.quantities.some((quantity) => quantity.size_id)
          );

          if (products.length > 0) {
            let index = 0;
            for (const product of products) {
              const colorSizesAux = product.quantities.reduce(
                (previous, current) => {
                  const colorId = current.color_id || index;

                  if (previous[colorId]) {
                    previous[colorId].sizes.push({
                      id: current.size.id,
                      value: current.size.size,
                      selected: false,
                    });
                  } else {
                    previous[colorId] = {
                      id: colorId,
                      color: current.color?.color || '',
                      selected: false,
                      sizes: [
                        {
                          id: current.size.id,
                          value: current.size.size,
                          selected: false,
                        },
                      ],
                    };
                  }

                  return previous;
                },
                {} as any
              );
              const colors = product.quantities
                .map((quantity) => ({
                  id: quantity.color?.id || '',
                  value: quantity.color?.color || '',
                  color: quantity.color?.name,
                  selected: false,
                }))
                .filter(
                  (obj, idx, self) =>
                    idx === self.findIndex((t) => t.id === obj.id)
                );
              setProductsArray((prevState) => [
                ...prevState,
                {
                  id: product.id,
                  kit_id: kit.id,
                  product: product.name,
                  colors: colors.filter((color) => color.id),
                  colorSizes: Object.values(colorSizesAux),
                },
              ]);

              index += 1;
            }
          }
        }
      }
      setEventId(response.data.id);
    });
  }, [params.slug]);

  useEffect(() => {
    const groupedByOrderCode = ticketsData.reduce((acc, ticket) => {
      const { address } = ticket.batchCategory.batch.event;
      const orderCode = ticket.orderItem.order.code;
      if (acc[orderCode] && acc[orderCode].tickets) {
        acc[orderCode].tickets.push({
          id: ticket.id,
          batch_category_id: ticket.batchCategory.id,
          modality_id: ticket.modality?.id,
          event_id: ticket.batchCategory.batch.event.id,
          user_id: ticket.user_id,
          orderCode,
          code: ticket.code,
          email: ticket.email || '',
          event: ticket.batchCategory.batch.event.name,
          event_date: ticket.batchCategory.batch.event.date,
          myTicket:
            (user.additionalInformation.document &&
              ticket.document === user.additionalInformation.document) ||
            false,
          reveled: false,
          name: ticket.name || 'Não informado',
          document: ticket.document || '',
          category: ticket.batchCategory.category.name,
          modality: ticket.modality?.name || '-',
          address: `${address.street}, Nº ${address.number}, ${address.neighborhood}, ${address.city}/${address.state}, CEP: ${address.zip_code}`,
          status: ticket.status,
          kit: ticket.kitUser
            ? {
                color: ticket.kitUser.color?.name,
                size: ticket.kitUser.size?.size,
              }
            : undefined,
        });
      } else {
        acc[orderCode] = {
          status: ticket.orderItem.order.status,
          paymentMethod:
            ticket.orderItem.order.payment.payment_type === 'credit-card' ||
            ticket.orderItem.order.payment.payment_type === 'credit_card'
              ? 'Cartão de crédito'
              : 'PIX',
          qrCode: ticket.orderItem.order.payment.qr_code,
          tickets: [
            {
              id: ticket.id,
              batch_category_id: ticket.batchCategory.id,
              modality_id: ticket.modality?.id,
              event_id: ticket.batchCategory.batch.event.id,
              user_id: ticket.user_id,
              orderCode,
              code: ticket.code,
              event: ticket.batchCategory.batch.event.name,
              event_date: ticket.batchCategory.batch.event.date,
              myTicket:
                (user.additionalInformation.document &&
                  ticket.document === user.additionalInformation.document) ||
                false,
              reveled: false,
              name: ticket.name || 'Não informado',
              email: ticket.email || '',
              document: ticket.document || '',
              category: ticket.batchCategory.category.name,
              modality: ticket.modality?.name || '-',
              address: `${address.street}, Nº ${address.number}, ${address.neighborhood}, ${address.city}/${address.state}, CEP: ${address.zip_code}`,
              status: ticket.status,
              kit: ticket.kitUser
                ? {
                    color: ticket.kitUser.color?.name,
                    size: ticket.kitUser.size?.size,
                  }
                : undefined,
            },
          ],
          created_at: format(
            parseISO(ticket.orderItem.order.created_at),
            'dd/MM/yyyy - HH:mm'
          ),
        };
      }
      return acc;
    }, {} as IOrder);

    setOrders(groupedByOrderCode);
  }, [ticketsData, user.additionalInformation.document]);

  const handleLoadTickets = useCallback(
    async (pageData) => {
      try {
        setTicketError('');
        const response = await api.get<ITicketResponse>(
          `tickets/users/${params.slug}`,
          {
            params: {
              page: pageData,
            },
          }
        );

        setEvent({
          name: response.data.data[0].batchCategory.batch.event.name,
          description:
            response.data.data[0].batchCategory.batch.event.description,
          date: format(
            parseISO(response.data.data[0].batchCategory.batch.event.date),
            'dd/MM/yyyy'
          ),
          address: `${
            response.data.data[0].batchCategory.batch.event.address.street
          } n˚ ${
            response.data.data[0].batchCategory.batch.event.address.number
          },
          ${
            response.data.data[0].batchCategory.batch.event.address.complement
              ? ` ${response.data.data[0].batchCategory.batch.event.address.complement},`
              : ''
          } ${
            response.data.data[0].batchCategory.batch.event.address.neighborhood
          } - ${response.data.data[0].batchCategory.batch.event.address.city}/${
            response.data.data[0].batchCategory.batch.event.address.state
          }, CEP: ${
            response.data.data[0].batchCategory.batch.event.address.zip_code
          }`,
        });

        if (pageData === 1) {
          setTicketsData(response.data.data);
        } else {
          setTicketsData((state) => [...state, ...response.data.data]);
        }
        setTotalPages(response.data.pages);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoadingMore(false);
      }
    },
    [params.slug]
  );

  useEffect(() => {
    handleLoadTickets(1);
  }, [handleLoadTickets]);

  const handleSaveAsPDF = useCallback(
    (orderCode: string, index: number, name: string) => {
      const newOrders = { ...orders };
      if (newOrders[orderCode].status === 'Pago') {
        if (newOrders[orderCode].tickets[index].document === '') {
          setTicketSelected(newOrders[orderCode].tickets[index]);
          setShow(true);
        } else {
          const divElement = document.getElementById(
            `ticket-${newOrders[orderCode].tickets[index].code}`
          );
          if (divElement) {
            const pdfWidth = divElement.offsetWidth;
            const pdfHeight = divElement.offsetHeight;
            html2canvas(divElement, {
              width: width > 550 ? pdfWidth + 85 : pdfWidth + 45,
              height: pdfHeight + 30,
            }).then((canvas) => {
              const imgData = canvas.toDataURL('image/png');
              const pdf = new jsPDF({
                unit: 'px',
                format: [pdfWidth, pdfHeight],
              });
              pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth, pdfHeight);
              pdf.save(`Ingresso-de-${name}.pdf`);
            });
          }
        }
      }
    },
    [orders, width]
  );

  const handleLoad = useCallback(async () => {
    if (!loadingMore) {
      setLoadingMore(true);
      if (page < totalPages) {
        await handleLoadTickets(page + 1);
        setPage(page + 1);
      } else {
        setLoadingMore(false);
      }
    }
  }, [handleLoadTickets, loadingMore, page, totalPages]);

  const handleClickRevealQrCode = useCallback(
    (orderCode, ticketIndex) => {
      const newOrders = { ...orders };
      if (newOrders[orderCode].status === 'Pago') {
        if (newOrders[orderCode].tickets[ticketIndex].document === '') {
          setTicketSelected(newOrders[orderCode].tickets[ticketIndex]);
          setShow(true);
        } else {
          newOrders[orderCode].tickets[ticketIndex].reveled = true;
          setOrders(newOrders);
        }
      }
    },
    [orders]
  );

  const handleClose = useCallback(() => {
    setTicketError('');
    setTicketSelected({} as ITicket);
    setShow(false);
    setMyTicket(false);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setSending(true);
        formRef.current?.setErrors({});

        const productData = productsArray.find(
          (product) => product.colorSizes.length > 0
        );

        let colorSizeData: ISizeColor | undefined;
        if (productData) {
          colorSizeData = productData.colorSizes.find((colorSize) =>
            data.color
              ? colorSize.id === parseInt(data.color, 10)
              : colorSize.id === 0
          );
        }

        if (!myTicket) {
          const schema = Yup.object().shape({
            document: Yup.string()
              .test('cpf', 'CPF inválido', (value) =>
                cpf.isValid(value as string)
              )
              .required('O CPF é obrigatório'),
            name: Yup.string().required('O nome é obrigatório'),
            phone: Yup.string().required('O telefone é obrigatório'),
            email: Yup.string().email().required('O e-mail é obrigatório'),
            color: Yup.string().when(`$checkColor`, {
              is: (checkColor: boolean) => checkColor,
              then: Yup.string().required('A cor é obrigatória'),
              otherwise: Yup.string(),
            }),
            size: Yup.string().when(`$checkSize`, {
              is: (checkSize: boolean) => checkSize,
              then: Yup.string().required('O tamanho é obrigatório'),
              otherwise: Yup.string(),
            }),
            ...questions.reduce((previous, question) => {
              if (question.isRequired) {
                if (question.type === 'free-text') {
                  previous[`answer-text-${question.id}`] = Yup.string().when(
                    `$checkText-${question.id}`,
                    {
                      is: (checkText: boolean) => checkText,
                      then: Yup.string().required('A resposta é obrigatória'),
                      otherwise: Yup.string(),
                    }
                  );
                }
                if (question.type === 'unique-choice') {
                  previous[`answer-radio-${question.id}`] = Yup.string().when(
                    `$checkRadio-${question.id}`,
                    {
                      is: (checkRadio: boolean) => checkRadio,
                      then: Yup.string().required('A resposta é obrigatória'),
                      otherwise: Yup.string(),
                    }
                  );
                }
                if (question.type === 'multiple-choice') {
                  previous[`answer-multiple-${question.id}`] =
                    Yup.string().when(`$checkMultiple-${question.id}`, {
                      is: (checkMultiple: boolean) => checkMultiple,
                      then: Yup.string().required('A resposta é obrigatória'),
                      otherwise: Yup.string(),
                    });
                }
              }

              return previous;
            }, {} as { [key: string]: Yup.StringSchema<string | undefined, Record<string, any>, string | undefined> }),
          });

          await schema.validate(data, {
            abortEarly: false,
            context: {
              checkColor: productData && productData.colors.length > 0,
              checkSize: colorSizeData && colorSizeData.sizes.length > 0,
              ...questions.reduce((previous, question) => {
                if (question.isRequired) {
                  if (question.type === 'free-text') {
                    previous[`checkText-${question.id}`] = question.isRequired;
                  }
                  if (question.type === 'unique-choice') {
                    previous[`checkRadio-${question.id}`] = question.isRequired;
                  }
                  if (question.type === 'multiple-choice') {
                    previous[`checkMultiple-${question.id}`] =
                      question.isRequired;
                  }
                }

                return previous;
              }, {} as { [key: string]: boolean }),
            },
          });
        } else {
          const schema = Yup.object().shape({
            color: Yup.string().when(`$checkColor`, {
              is: (checkColor: boolean) => checkColor,
              then: Yup.string().required('A cor é obrigatória'),
              otherwise: Yup.string(),
            }),
            size: Yup.string().when(`$checkSize`, {
              is: (checkSize: boolean) => checkSize,
              then: Yup.string().required('O tamanho é obrigatório'),
              otherwise: Yup.string(),
            }),
            ...questions.reduce((previous, question) => {
              if (question.isRequired) {
                if (question.type === 'free-text') {
                  previous[`answer-text-${question.id}`] = Yup.string().when(
                    `$checkText-${question.id}`,
                    {
                      is: (checkText: boolean) => checkText,
                      then: Yup.string().required('A resposta é obrigatória'),
                      otherwise: Yup.string(),
                    }
                  );
                }
                if (question.type === 'unique-choice') {
                  previous[`answer-radio-${question.id}`] = Yup.string().when(
                    `$checkRadio-${question.id}`,
                    {
                      is: (checkRadio: boolean) => checkRadio,
                      then: Yup.string().required('A resposta é obrigatória'),
                      otherwise: Yup.string(),
                    }
                  );
                }
                if (question.type === 'multiple-choice') {
                  previous[`answer-multiple-${question.id}`] =
                    Yup.string().when(`$checkMultiple-${question.id}`, {
                      is: (checkMultiple: boolean) => checkMultiple,
                      then: Yup.string().required('A resposta é obrigatória'),
                      otherwise: Yup.string(),
                    });
                }
              }

              return previous;
            }, {} as { [key: string]: Yup.StringSchema<string | undefined, Record<string, any>, string | undefined> }),
          });

          await schema.validate(data, {
            abortEarly: false,
            context: {
              checkColor: productData && productData.colors.length > 0,
              checkSize: colorSizeData && colorSizeData.sizes.length > 0,
              ...questions.reduce((previous, question) => {
                if (question.isRequired) {
                  if (question.type === 'free-text') {
                    previous[`checkText-${question.id}`] = question.isRequired;
                  }
                  if (question.type === 'unique-choice') {
                    previous[`checkRadio-${question.id}`] = question.isRequired;
                  }
                  if (question.type === 'multiple-choice') {
                    previous[`checkMultiple-${question.id}`] =
                      question.isRequired;
                  }
                }

                return previous;
              }, {} as { [key: string]: boolean }),
            },
          });
        }

        const responseCheck = await api.get(
          `tickets/events/${ticketSelected.event_id}/${
            myTicket && user.additionalInformation.document
              ? user.additionalInformation.document
              : data.document
          }`
        );

        if (responseCheck.data) {
          throw new Error('ingresso repetido');
        }

        const formData = {
          batch_category_id: ticketSelected.batch_category_id,
          modality_id: ticketSelected.modality_id,
          user_id: ticketSelected.user_id,
          document:
            myTicket && user.additionalInformation.document
              ? user.additionalInformation.document
              : data.document,
          name: myTicket ? user.name : data.name,
          email: myTicket ? user.email : data.email,
          phone:
            myTicket && user.additionalInformation.phone
              ? user.additionalInformation.phone
              : data.phone,
          status: ticketSelected.status === 'Disponível' ? 'Usado' : undefined,
        };

        const response = await api.put(
          `tickets/${ticketSelected.id}`,
          formData
        );

        if (answers.length > 0) {
          for (const answer of answers) {
            const formDataAnswer = {
              ticket_id: response.data.id,
              event_id: eventId,
              question_type_id: answer.question_type_id,
              question_id: answer.question_id,
              alternative_id: answer.alternative_id,
              text: answer.text,
            };

            await api.post('answers', formDataAnswer);
          }
        }

        if (productsArray.length > 0) {
          for (const product of productsArray) {
            const kitUserFormData = {
              event_id: eventId,
              kit_id: product.kit_id,
              user_id: ticketSelected.user_id,
              ticket_id: ticketSelected.id,
              color_id: data.color,
              size_id: data.size,
            };

            await api.post('kits-users', kitUserFormData);
          }
        }

        if (myTicket) {
          const formDataTicket = {
            user_id: user.id,
            document: user.additionalInformation.document || data.document,
            phone: user.additionalInformation.phone || data.phone,
          };
          await api.put(
            `additional-informations/${user.additionalInformation.id}`,
            formDataTicket
          );

          updateUser({
            ...user,
            additionalInformation: {
              ...user.additionalInformation,
              document: formDataTicket.document,
              phone: formDataTicket.phone,
            },
          });
        }

        const newOrders = { ...orders };
        const ticketIndex = newOrders[
          ticketSelected.orderCode
        ].tickets.findIndex((ticket) => ticket.id === ticketSelected.id);

        if (ticketIndex >= 0) {
          newOrders[ticketSelected.orderCode].tickets[ticketIndex].name =
            response.data.name;
          newOrders[ticketSelected.orderCode].tickets[
            ticketIndex
          ].document = `****${response.data.document.slice(4, 11)}***`;
          newOrders[ticketSelected.orderCode].tickets[ticketIndex].reveled =
            true;
          newOrders[ticketSelected.orderCode].tickets[ticketIndex].myTicket =
            myTicket;
          newOrders[ticketSelected.orderCode].tickets[ticketIndex].myTicket =
            myTicket;
          newOrders[ticketSelected.orderCode].tickets[ticketIndex].kit = {
            color: colorSizeData?.color,
            size:
              colorSizeData &&
              colorSizeData.sizes.find(
                (size) => size.id === parseInt(data.size, 10)
              )?.value,
          };
        }

        Swal.fire({
          icon: 'success',
          iconColor: '#ff6900',
          title: `Esse ingresso foi atribuído para ${
            ticketSelected.name.split(' ')[0]
          }`,
          showCloseButton: true,
        }).finally(() => {
          const divElement = document.getElementById(
            `ticket-${ticketSelected.code}`
          );

          if (divElement) {
            const pdfWidth = divElement.offsetWidth;
            const pdfHeight = divElement.offsetHeight;
            html2canvas(divElement, {
              width: width > 550 ? pdfWidth + 85 : pdfWidth + 45,
              height: pdfHeight + 30,
            }).then((canvas) => {
              const imgData = canvas.toDataURL('image/png');
              const pdf = new jsPDF({
                unit: 'px',
                format: [pdfWidth, pdfHeight],
              });
              pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth, pdfHeight);
              const ticketFile = pdf.output('blob');

              const ticketFormData = new FormData();
              ticketFormData.append('ticket', ticketFile);
              ticketFormData.append('ticket_id', ticketSelected.id.toString());
              if (!myTicket) {
                api.post('tickets/emails', ticketFormData);
              }
            });
          }
        });
        setOrders(newOrders);
        handleClose();
        setSending(false);
      } catch (error) {
        setSending(false);
        const err = error as any;
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else if (err.message === 'ingresso repetido') {
          setTicketError(
            data.document === user.additionalInformation.document
              ? 'Você já possui um ingresso para este evento'
              : 'Já existe um ingresso com este CPF para este evento'
          );
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [
      productsArray,
      myTicket,
      ticketSelected.event_id,
      ticketSelected.batch_category_id,
      ticketSelected.modality_id,
      ticketSelected.user_id,
      ticketSelected.status,
      ticketSelected.id,
      ticketSelected.orderCode,
      ticketSelected.name,
      ticketSelected.code,
      user,
      answers,
      orders,
      handleClose,
      questions,
      eventId,
      updateUser,
      width,
    ]
  );

  const handleChangeMyTicket = useCallback(() => {
    setTicketError('');
    setMyTicket((state) => !state);
  }, []);

  const handleChangeDocument = useCallback(async (e) => {
    const { value } = e.target;

    if (value.length === 14) {
      const response = await api.get(`tickets/document/${value}`);
      if (response.data.length > 0) {
        setTicketSelected((oldState) => ({
          ...oldState,
          document: value,
          name: response.data[0].name,
          phone: response.data[0].phone,
          email: response.data[0].email,
        }));
      }
    }
  }, []);

  const handleClickQrCode = useCallback((order) => {
    setOrderSelected(order);
    setShowPix(true);
  }, []);

  const handleClickCopyPix = useCallback(async () => {
    await navigator.clipboard.writeText(orderSelected.qrCode as string);
    Toast.fire({
      icon: 'success',
      iconColor: '#ff6900',
      title: 'Código copiado!',
    });
  }, [orderSelected]);

  const handleChangeColor = useCallback((option, idx) => {
    setProductsArray((oldState) => {
      const products = oldState.slice();
      products[idx].colorSizes = products[idx].colorSizes.map((colorSize) => {
        return {
          ...colorSize,
          selected: colorSize.color === option.value,
        };
      });
      return products;
    });
  }, []);

  const handleChangeAnswer = useCallback(
    (answer, questionId) => {
      const answerExist = answers.some(
        (answerData) => answerData.question_id === questionId
      );

      let dataAnswers: IAnswer[] = answers;
      if (answerExist) {
        dataAnswers = dataAnswers.filter(
          (answerData) => answerData.question_id !== questionId
        );

        dataAnswers.push(...answer);
      } else {
        dataAnswers.push(...answer);
      }
      setAnswers(dataAnswers);
    },
    [answers]
  );

  return (
    <Container>
      <InfiniteScroll onInfiniteLoad={handleLoad} className="infinite-scroll">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bg-white event px-3 py-3 mt-3">
                <ButtonBack className="" />

                <h1 className="my-3">{event.name}</h1>
                <p className="mb-1">Data: {event.date}</p>
                <p className="mb-3">
                  Local: {event.address?.replace('n˚ 0', 'S/N')}
                </p>
              </div>
            </div>
            <div className="col-12">
              <h2 className="h5 text-black mt-3">Meus Ingressos</h2>
              {Object.entries(orders).map(([orderCode, order]) => (
                <>
                  {order.status !== 'Expirado' && (
                    <div key={orderCode} className="mt-3">
                      <h4 className="h6">Pedido #{orderCode}</h4>
                      <h4 className="h6">{order.created_at}</h4>
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="h6">
                          {order.status} - {order.paymentMethod}
                        </h4>

                        {order.status === 'Aguardando pagamento' &&
                          order.paymentMethod === 'PIX' &&
                          order.qrCode && (
                            <button
                              type="button"
                              className="btn btn-primary ms-2"
                              onClick={() => handleClickQrCode(order)}
                            >
                              Ver QR code PIX
                            </button>
                          )}
                      </div>

                      {order.tickets.map((ticket, index) => (
                        <div key={ticket.id} className="position-relative">
                          <button
                            onClick={() => {
                              if (
                                order.status === 'Aguardando pagamento' &&
                                order.paymentMethod === 'PIX' &&
                                order.qrCode
                              ) {
                                handleClickQrCode(order);
                              } else {
                                handleSaveAsPDF(orderCode, index, ticket.name);
                              }
                            }}
                            type="button"
                            className="share border-0 bg-transparent"
                          >
                            <IoShareSocialSharp color="#00A3E0" size={24} />
                          </button>

                          {ticket.name === 'Não informado' && (
                            <span className="pending d-inline-block text-white bg-danger rounded-pill px-3 py-1">
                              Nomeação pendente
                            </span>
                          )}

                          <div
                            key={ticket.id}
                            id={`ticket-${ticket.code}`}
                            className="bg-ticket position-relative mt-3"
                          >
                            <BtnRevealQrCode
                              type="button"
                              className="border-0 bg-transparent d-block mx-auto"
                              onClick={() => {
                                if (
                                  order.status === 'Aguardando pagamento' &&
                                  order.paymentMethod === 'PIX' &&
                                  order.qrCode
                                ) {
                                  handleClickQrCode(order);
                                } else {
                                  handleClickRevealQrCode(orderCode, index);
                                }
                              }}
                              reveled={
                                ticket.document !== '' &&
                                order.status === 'Pago'
                              }
                            >
                              <div className="text-center p-3">
                                <QRCode
                                  value={`Identificador: #${
                                    ticket.code
                                  };\nNome: ${ticket.name};\nDocumento: ${
                                    ticket.document || undefined
                                  };\nE-mail: ${ticket.email};\nId do evento: ${
                                    ticket.event_id
                                  };\nNome do evento: ${
                                    ticket.event
                                  };\nData do evento: ${format(
                                    parseISO(ticket.event_date),
                                    'dd/MM/yyyy'
                                  )};\nModalidade: ${
                                    ticket.modality
                                  };\nCategoria: ${
                                    ticket.category
                                  };\n\nInformações do Kit ${
                                    ticket.kit
                                      ? `${
                                          ticket.kit.color
                                            ? `\nCor: ${ticket.kit.color};`
                                            : ''
                                        }${
                                          ticket.kit.size
                                            ? `\nTamanho: ${ticket.kit.size}`
                                            : ''
                                        }`
                                      : '\nNão Possui Kit'
                                  };
                              `}
                                  removeQrCodeBehindLogo
                                  logoImage={logo}
                                  logoPadding={5}
                                  size={250}
                                  ecLevel="L"
                                />
                                <p className="mx-auto px-2 py-1 mb-0 mt-2 ident">
                                  Identificador <br />#{ticket.code}
                                </p>
                              </div>
                              <div className="blur">
                                <FiEye size={64} color="#000" />
                              </div>
                            </BtnRevealQrCode>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="circle-left" />
                              <div className="small-circle" />
                              <div className="small-circle" />
                              <div className="small-circle" />
                              <div className="small-circle" />
                              <div className="small-circle" />
                              <div className="small-circle" />
                              <div className="small-circle" />
                              <div className="small-circle" />
                              <div className="small-circle" />
                              <div className="small-circle" />
                              <div className="small-circle" />
                              <div className="small-circle" />
                              <div className="small-circle" />
                              <div className="small-circle" />
                              <div className="small-circle" />
                              <div className="small-circle" />
                              <div className="circle-right" />
                            </div>
                            <div className="d-flex justify-content-between align-items-center px-3 mt-3 mb-4">
                              <h2 className="h5 mb-0">
                                {ticket.event}
                                <br />
                                <span className="data">
                                  <span>Data do evento:</span>{' '}
                                  {format(
                                    parseISO(ticket.event_date),
                                    'dd/MM/yyyy'
                                  )}
                                </span>
                              </h2>

                              {ticket.myTicket && (
                                <span className="my-ticket px-2 py-1">
                                  Meu ingresso
                                </span>
                              )}
                            </div>
                            <div className="px-3 pb-3">
                              <p className="mb-2">
                                <span className="me-2">Nome: </span>
                                {ticket.name}
                              </p>
                              <p className="mb-2">
                                <span className="me-2">Documento: </span>
                                {ticket.document
                                  ? `****${ticket.document.slice(4, 11)}***`
                                  : 'Não informado'}
                              </p>
                              <p className="mb-2">
                                <span className="me-2">Modalidade: </span>
                                {ticket.modality}
                              </p>
                              <p className="mb-2">
                                <span className="me-2">Categoria: </span>
                                {ticket.category}
                              </p>

                              <p className="mb-0">
                                <span className="me-2">Endereço: </span>
                                {ticket.address?.replace('Nº 0', 'S/N')}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </InfiniteScroll>

      <Modal
        className="modal-filter"
        size="xl"
        centered
        show={show}
        onHide={handleClose}
      >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Modal.Header className="justify-content-between align-items-center border-0 px-3 px-sm-5 pb-0 pb-lg-4 pt-0">
            <Modal.Title className="mb-3 mt-4">
              <h2 className="title h5 fw-semibold mb-0">Dados do ingresso</h2>
            </Modal.Title>
            <button type="button" onClick={handleClose} className="border-0">
              <IoClose size={20} color="#989898" />
            </button>
          </Modal.Header>
          <Modal.Body className="pt-0 px-3 px-sm-5 mx-lg-4">
            <div className="mb-3">
              <h5 className="h6">
                <b>Modalidade:</b> {ticketSelected.modality}
              </h5>
              <h5 className="h6">
                <b>Categoria:</b> {ticketSelected.category}
              </h5>
            </div>
            {!Object.values(orders).find(
              (order) => !!order.tickets.find((ticket) => ticket.myTicket)
            ) && (
              <InputCheckbox
                type="checkbox"
                name="myTicket"
                className="my-3 d-block"
                options={[
                  {
                    label: 'Este ingresso é o meu',
                    value: 'Este ingresso é o meu',
                    selected: myTicket,
                  },
                ]}
                onChange={handleChangeMyTicket}
                error={ticketError}
              />
            )}
            {(!myTicket ||
              (myTicket && !user.additionalInformation.document)) && (
              <label className="mb-3">
                <span className="fw-normal d-block mb-2">Documento*</span>
                <div className="d-flex">
                  <Select
                    className="bd-input w-25"
                    name="documents"
                    readOnly
                    options={[
                      {
                        value: 'CPF',
                        id: 'CPF',
                        selected: true,
                      },
                    ]}
                  />
                  <div className="mt-0 ms-1 w-75">
                    <InputMask
                      kind="cpf"
                      name="document"
                      className="bd-input"
                      error={ticketError}
                      onChange={handleChangeDocument}
                    />
                  </div>
                </div>
              </label>
            )}
            {(!myTicket || (myTicket && !user.name)) && (
              <label className="mb-3 w-100">
                <span className="fw-normal d-block mb-2">Nome Completo*</span>
                <Input
                  name="name"
                  className="bd-input"
                  defaultValue={
                    ticketSelected.name !== 'Não informado'
                      ? ticketSelected.name
                      : undefined
                  }
                />
              </label>
            )}
            {(!myTicket || (myTicket && !user.additionalInformation.phone)) && (
              <label className="mb-3 w-100">
                <span className="fw-normal d-block mb-2">Telefone*</span>
                <InputMask
                  kind="cel-phone"
                  name="phone"
                  className="bd-input"
                  value={ticketSelected.phone}
                />
              </label>
            )}
            {(!myTicket || (myTicket && !user.email)) && (
              <label className="mb-3 w-100">
                <span className="fw-normal d-block mb-2 size">E-mail*</span>
                <Input
                  type="email"
                  name="email"
                  className="bd-input"
                  defaultValue={ticketSelected.email}
                />
              </label>
            )}

            {productsArray.length > 0 && (
              <>
                {productsArray.map((product, idx) => (
                  <Fragment key={product.id}>
                    {product.colors?.length > 0 && (
                      <label className="mb-3 w-100">
                        <span className="fw-normal d-block mb-2">
                          Cor do(a) {product.product}*
                        </span>
                        <Select
                          type="color"
                          className="bd-input select-tam"
                          name="color"
                          options={product.colors}
                          onChange={(option) => handleChangeColor(option, idx)}
                        />
                      </label>
                    )}
                    {product.colorSizes.map((colorSize) => (
                      <Fragment key={colorSize.id}>
                        {colorSize.color && colorSize.selected ? (
                          <label className="mb-3 w-100">
                            <span className="fw-normal d-block mb-2">
                              Tamanho do(a) {product.product}*
                            </span>
                            <Select
                              className="bd-input select-tam"
                              name="size"
                              options={colorSize.sizes}
                            />
                          </label>
                        ) : (
                          <>
                            {!colorSize.color && (
                              <label className="mb-3 w-100">
                                <span className="fw-normal d-block mb-2">
                                  Tamanho do(a) {product.product}*
                                </span>
                                <Select
                                  className="bd-input select-tam"
                                  name="size"
                                  options={colorSize.sizes}
                                />
                              </label>
                            )}
                          </>
                        )}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </>
            )}
            {Object.keys(questions).length > 0 && (
              <div className="bg-information">
                <h2 className="h6">Mais informações</h2>
                {questions.map((question) => (
                  <Question
                    key={question.id}
                    question={question}
                    onChangeAnswer={(answer) => {
                      handleChangeAnswer(answer, question.id);
                    }}
                  />
                ))}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="border-0 pb-4 px-3">
            <button
              type="submit"
              disabled={sending}
              className="w-100 mx-0 btn-filter d-flex align-items-center justify-content-center btn h6 fw-normal py-2 mt-2 mb-0 btn-login"
            >
              {sending ? 'Enviando...' : 'Enviar'}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={showPix}>
        <Modal.Header className="d-flex align-items-center justify-content-center">
          <button
            type="button"
            onClick={() => setShowPix(false)}
            className="close-btn"
          >
            <KrClose size={20} color="#000" />
          </button>
          <Modal.Title className="mb-0">Qr Code Pix</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
          <QRCode
            value={orderSelected.qrCode}
            removeQrCodeBehindLogo
            logoImage={logoQrcode}
            logoPadding={5}
            size={250}
            ecLevel="L"
          />
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center">
          <button
            type="button"
            className="copy-btn border-0 py-2 px-4 text-center mt-3"
            onClick={handleClickCopyPix}
          >
            Copiar código pix
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Ticket;
