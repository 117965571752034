import React from 'react';
import ReactDOM from 'react-dom';
import Clarity from '@microsoft/clarity';

import App from './App';

Clarity.init('q0ju6f1o6i');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
