import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IBtnRevealQrCode {
  reveled: boolean;
}

export const Container = styled.div`
  background: #f4f0ec;

  .infinite-scroll {
    max-height: 100vh;
    overflow: auto;
    padding-bottom: 20%;
    .padding-bs {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }

    ::-webkit-scrollbar {
      width: 0;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent !important;
    }
  }

  .pending {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 2;
    pointer-events: none;
  }

  h1 {
    color: #ff6900;
    font-family: 'Poppins';
    font-size: 26px;
    font-weight: 500;
    line-height: 108%;
  }

  h2 {
    color: #393939;
    line-height: 29px !important;
  }

  .share {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 2;
  }

  .data {
    color: #393939;
    font-size: 14px;
    font-weight: 400;
    span {
      color: #8c8c8c;
    }
  }

  .my-ticket {
    color: #fff;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;

    border-radius: 4px;
    background: #00a3e0;
    min-width: 103px;
  }

  .event p {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 400;
  }

  .bg-white {
    border-radius: 8px;
  }

  .circle-left {
    width: 10px;
    height: 20px;
    margin-left: -2px;
    //border-radius: 50px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

    border-bottom: 2px solid #dfdfdf;
    border-top: 2px solid #dfdfdf;
    border-right: 2px solid #dfdfdf;
    background: #f4f0ec;
  }

  .circle-right {
    width: 10px;
    height: 20px;
    margin-right: -2px;
    //border-radius: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top: 2px solid #dfdfdf;
    border-bottom: 2px solid #dfdfdf;
    border-left: 2px solid #dfdfdf;
    background: #f4f0ec;
  }

  .small-circle {
    border: 1px solid #dfdfdf;
    width: 6px;
    height: 6px;
    background: #f4f0ec;
    border-radius: 10px;
  }
  .bg-ticket {
    border-radius: 16px;
    border: 2px solid #dfdfdf;

    background: #fff;

    img {
      width: 150px;
      height: 150px;
    }

    p {
      color: #393939;
      font-size: 14px;
      font-weight: 400;
      span {
        color: #8c8c8c;
      }
    }

    .ident {
      color: #a9a9a9;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 13px;
      border-radius: 8px;
      border: 1px solid #a9a9a9;
    }
  }
`;

export const BtnRevealQrCode = styled.button<IBtnRevealQrCode>`
  position: relative;

  .blur {
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;
    opacity: ${(props) => (props.reveled ? '0' : '1')};
    visibility: ${(props) => (props.reveled ? 'hidden' : 'visible')};

    svg {
      path {
        fill: #fff !important;
      }
    }
  }
`;

export const Modal = styled(ModalComponent)`
  backdrop-filter: blur(3px);

  .modal-content {
    .btn-login {
      border-radius: 4px;
      background-color: #ff6900;
      color: #fff;
    }

    .bd-input {
      border: 1px solid #e1e1e1;
      height: 40px;
      border-radius: 4px;

      input {
        width: 100%;
        flex: 1;
        background: transparent;
        border: 0;
        color: #000;
        transition-duration: 0.2s;

        ::placeholder {
          color: #8c8c8c;
        }
      }
      svg:not(.check-icon, .rotate-arrow) {
        display: none;
      }
    }

    .select-tam input {
      font-size: 16px;
    }
  }
  .check span {
    color: #202020;
    font-size: 16px;
    font-weight: 400;
  }

  .copy-btn {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 12px;
    border: none;
  }

  .bg-information {
    border-radius: 8px;
    background: #f6f6f6;
    padding: 16px;
  }
  .bg-white {
    border-radius: 8px;
  }
`;
