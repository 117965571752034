/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useState } from 'react';
import { HiCheck } from 'react-icons/hi';

import { Container, CheckboxInput, Error } from './styles';
import Input from '../Input';

export interface IOption {
  value: string | number;
  label: string | JSX.Element;
  selected?: boolean;
  labelHtml?: boolean;
}

interface IInputCheckbox {
  type: 'checkbox' | 'radio';
  name: string;
  options: IOption[];
  className?: string;
  onChange?(options: IOption[]): void;
  error?: string;
}

const InputCheckbox: React.FC<IInputCheckbox> = ({
  type,
  name,
  options,
  className,
  onChange,
  error,
}) => {
  const [data, setData] = useState<IOption[]>([]);

  useEffect(() => {
    setData(
      options.map((optionData) => ({
        label: optionData.labelHtml ? 'teste' : optionData.label,
        value: optionData.value,
        labelHtml: optionData.labelHtml,
        selected: optionData.selected,
      }))
    );
  }, [options]);

  const handleChange = useCallback(
    (option) => {
      let newData = data.slice();
      const dataIndex = newData.findIndex(
        (optionData) => optionData.value === option.value
      );

      if (dataIndex >= 0) {
        if (type === 'radio') {
          newData = newData.map((optionData) => ({
            ...optionData,
            selected: false,
          }));
        }
        newData[dataIndex].selected = !newData[dataIndex].selected;
        setData(
          newData.map((optionData) => ({
            label: optionData.labelHtml ? 'teste' : optionData.label,
            value: optionData.value,
            labelHtml: optionData.labelHtml,
            selected: optionData.selected,
          }))
        );
      }
      if (onChange) {
        onChange(newData);
      }
    },
    [data, onChange, type]
  );

  return (
    <>
      <Container className={className}>
        {options.map((option, index) => (
          <CheckboxInput
            htmlFor={`${name}_${index}`}
            selected={option.selected}
            className={option.selected ? 'check-selected' : ''}
          >
            <div>
              {option.selected && (
                <HiCheck size={16} color="#fff" className="check-icon" />
              )}
            </div>
            <input
              type={type}
              id={`${name}_${index}`}
              name={type === 'checkbox' ? `${name}_checkbox` : name}
              value={option.value}
              onChange={() => handleChange(option)}
              className="inputData"
            />
            {typeof option.label === 'string' && option.labelHtml ? (
              <span dangerouslySetInnerHTML={{ __html: option.label }} />
            ) : (
              <span>{option.label}</span>
            )}
          </CheckboxInput>
        ))}
      </Container>
      {error && <Error className="small text-danger error">{error}</Error>}
      <Input
        type="hidden"
        name={name}
        className="d-none"
        value={
          data.some((option) => option.selected)
            ? JSON.stringify(data)
            : undefined
        }
      />
    </>
  );
};

export default InputCheckbox;
