import styled from 'styled-components';

export const Container = styled.div`
  .bg-information {
    border-radius: 8px;
    background: #f6f6f6;
    padding: 16px;
  }
  .bg-white {
    border-radius: 8px;
  }

  .input-radio,
  .input-checkbox {
    label {
      margin-left: 0px !important;
      border-radius: 47px;
      border: 0.5px solid #c8c8c8;
      padding: 8px 16px;
      margin: 8px;

      div {
        min-height: 18px;
        min-width: 18px;
        border-color: #c7c7c7;
      }
    }
  }

  .input-radio label {
    div {
      border-radius: 50%;
    }
    svg {
      display: none;
    }
  }

  .check-selected {
    background: #f9f5f5;
  }
`;
