/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Input from '~/components/Input';
import InputCheckbox from '~/components/InputCheckbox';
import InputMask from '~/components/InputMask';
import Select, { IOption } from '~/components/Select';
import { useAuth } from '~/hooks/Auth';

import api from '~/services/api';
import { IProductColorSize, ITicket } from '..';
import { IQuestion } from '~/pages/Ticket';
import Question from '~/components/Question';

export interface IAnswer {
  event_id: number;
  question_type_id: number;
  question_id: number;
  alternative_id: number;
  text: string;
}

interface IAlternativesResponse {
  id: number;
  name: string;
}

export interface IQuestionResponse {
  id: number;
  event_id: number;
  question_type_id: number;
  name: string;
  order: number;
  required?: boolean;
  alternatives: IAlternativesResponse[];
}

interface ITicketProps {
  ticket: ITicket;
  myTicketIndex: number;
  productsArray: IProductColorSize[];
  onChangeMyTicket: (ticket: ITicket) => void;
  onChangeTicket: (ticket: ITicket) => void;
  onChangeColor: (option: IOption, idx: number) => void;
  onChangeAnswers: (answers: IAnswer[]) => void;
  eventId?: number;
}

const Ticket: React.FC<ITicketProps> = ({
  ticket: ticketData,
  myTicketIndex,
  productsArray,
  onChangeMyTicket,
  onChangeTicket,
  onChangeColor,
  onChangeAnswers,
  eventId,
}) => {
  const { user } = useAuth();

  const [ticket, setTicket] = useState({} as ITicket);
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [answers, setAnswers] = useState<IAnswer[]>([]);

  useEffect(() => {
    api
      .get<IQuestionResponse[]>(`questions/events/${eventId}`)
      .then((response) => {
        const data = response.data.map<IQuestion>((question) => {
          let type = 'free-text';

          if (question.question_type_id === 2) {
            type = 'unique-choice';
          }

          if (question.question_type_id === 3) {
            type = 'multiple-choice';
          }

          const alternatives = question.alternatives.map((alternative) => ({
            id: alternative.id,
            text: alternative.name,
            new: false,
          }));

          return {
            id: question.id,
            question: question.name,
            type: type as IQuestion['type'],
            order: question.order,
            alternatives,
            isRequired: true,
          };
        });
        setQuestions(data);
      });
  }, [eventId]);

  useEffect(() => {
    setTicket(ticketData);
  }, [ticketData]);

  const handleChangeDocument = useCallback(
    async (e) => {
      const { value } = e.target;
      const newTicket = { ...ticket };
      if (value.length === 14) {
        const response = await api.get(`tickets/document/${value}`);
        if (response.data.length > 0) {
          newTicket.name = response.data[0].name;
          newTicket.document = value;
          newTicket.phone = response.data[0].phone;
          newTicket.email = response.data[0].email;
        } else {
          newTicket.name = newTicket.myTicket ? newTicket.name : '';
          newTicket.phone = newTicket.phone || '';
          newTicket.email = newTicket.myTicket ? newTicket.email : '';
          newTicket.document = value;
        }
      } else {
        newTicket.name = newTicket.name || '';
        newTicket.phone = newTicket.phone || '';
        newTicket.email = newTicket.email || '';
        newTicket.document = value;
      }
      onChangeTicket(newTicket);
    },
    [onChangeTicket, ticket]
  );

  const handleChangeName = useCallback(
    (e) => {
      const { value } = e.target;
      const newTicket = { ...ticket };
      newTicket.name = value;
      onChangeTicket(newTicket);
    },
    [onChangeTicket, ticket]
  );

  const handleChangePhone = useCallback(
    (e) => {
      const { value } = e.target;
      const newTicket = { ...ticket };
      newTicket.phone = value;
      onChangeTicket(newTicket);
    },
    [onChangeTicket, ticket]
  );

  const handleChangeEmail = useCallback(
    (e) => {
      const { value } = e.target;
      const newTicket = { ...ticket };
      newTicket.email = value;
      onChangeTicket(newTicket);
    },
    [onChangeTicket, ticket]
  );

  const handleChangeAnswer = useCallback(
    (answersData, questionId) => {
      const answerExist = answers.some(
        (answer) => answer.question_id === questionId
      );

      let dataAnswers: IAnswer[] = answers;
      if (answerExist) {
        dataAnswers = dataAnswers.filter(
          (answer) => answer.question_id !== questionId
        );

        dataAnswers.push(...answersData);
      } else {
        dataAnswers.push(...answersData);
      }
      onChangeAnswers(dataAnswers);
      setAnswers(dataAnswers);
    },
    [answers, onChangeAnswers]
  );

  return (
    <>
      {Object.keys(ticket).length > 0 && (
        <div
          key={ticket.item.batch_category_id}
          className="border-ticket p-3 mb-3"
        >
          <h3 className="h5 text-black">
            Ingresso #{(ticket.index + 1).toString().padStart(4, '0')}
          </h3>
          <p className="pb-3 border-bottom">
            Modalidade: {ticket.item.modality} | Categoria:{' '}
            {ticket.item.category}
          </p>
          {(ticket.myTicket || myTicketIndex === -1) && (
            <InputCheckbox
              type="checkbox"
              name={`my_ticket_${ticket.index}`}
              className="my-3 d-block"
              options={[
                {
                  label: 'Este ingresso é o meu',
                  value: 'Este ingresso é o meu',
                  selected: ticket.myTicket,
                },
              ]}
              onChange={() => onChangeMyTicket(ticket)}
            />
          )}
          <label
            className={`${
              myTicketIndex !== ticket.index ||
              !user.additionalInformation.document
                ? 'w-100 mb-3'
                : 'd-none'
            }`}
          >
            <span className="fw-normal d-block mb-2">Documento*</span>
            <div className="d-flex">
              <Select
                className="bd-input w-25"
                name={`documents_${ticket.index}`}
                readOnly
                options={[
                  {
                    value: 'CPF',
                    id: 0,
                    selected: true,
                  },
                ]}
              />
              <div className="col mt-0 ms-1">
                <InputMask
                  kind="cpf"
                  name={`document_${ticket.index}`}
                  className="bd-input"
                  onChange={handleChangeDocument}
                  value={ticket.document}
                />
              </div>
            </div>
          </label>
          <label
            className={`${
              myTicketIndex !== ticket.index || !user.name
                ? 'w-100 mb-3'
                : 'd-none'
            }`}
          >
            <span className="fw-normal d-block mb-2">Nome Completo*</span>
            <Input
              name={`name_${ticket.index}`}
              className="bd-input"
              onChange={handleChangeName}
              value={ticket.name}
            />
          </label>
          <label
            className={`${
              myTicketIndex !== ticket.index ||
              !user.additionalInformation.phone
                ? 'w-100 mb-3'
                : 'd-none'
            }`}
          >
            <span className="fw-normal d-block mb-2">Telefone*</span>
            <InputMask
              kind="cel-phone"
              name={`phone_${ticket.index}`}
              className="bd-input"
              onChange={handleChangePhone}
              value={ticket.phone}
            />
          </label>
          <label
            className={`${
              myTicketIndex !== ticket.index || !user.email
                ? 'w-100 mb-3'
                : 'd-none'
            }`}
          >
            <span className="fw-normal d-block mb-2 size">E-mail*</span>
            <Input
              type="email"
              name={`email_${ticket.index}`}
              className="bd-input"
              onChange={handleChangeEmail}
              value={ticket.email}
            />
          </label>
          {productsArray.length > 0 && (
            <>
              {productsArray.map((product, idx) => (
                <Fragment key={product.id}>
                  {product.colors?.length > 0 && (
                    <label className="mb-3 w-100">
                      <span className="fw-normal d-block mb-2">
                        Cor do(a) {product.product}*
                      </span>
                      <Select
                        type="color"
                        className="bd-input select-tam"
                        name={`color_${ticket.index}_${idx}`}
                        options={product.colors}
                        onChange={(option: any) => onChangeColor(option, idx)}
                      />
                    </label>
                  )}
                  {product.colorSizes.map((colorSize) => (
                    <Fragment key={colorSize.id}>
                      {colorSize.color && colorSize.selected ? (
                        <label className="mb-3 w-100">
                          <span className="fw-normal d-block mb-2">
                            Tamanho do(a) {product.product}*
                          </span>
                          <Select
                            className="bd-input select-tam"
                            name={`size_${ticket.index}_${idx}`}
                            options={colorSize.sizes}
                          />
                        </label>
                      ) : (
                        <>
                          {!colorSize.color && (
                            <label className="mb-3 w-100">
                              <span className="fw-normal d-block mb-2">
                                Tamanho do(a) {product.product}*
                              </span>
                              <Select
                                className="bd-input select-tam"
                                name={`size_${ticket.index}_${idx}`}
                                options={colorSize.sizes}
                              />
                            </label>
                          )}
                        </>
                      )}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </>
          )}
          {Object.keys(questions).length > 0 && (
            <div className="bg-information">
              <h3 className="h6">Mais informações</h3>
              {questions.map((question) => (
                <Question
                  key={question.id}
                  question={question}
                  onChangeAnswer={(answersData) =>
                    handleChangeAnswer(answersData, question.id)
                  }
                  ticketIndex={ticket.index}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Ticket;
